import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { 
  HomeIcon, 
  ChevronRightIcon,
  UserGroupIcon,
  BellAlertIcon,
  NewspaperIcon
} from '@heroicons/react/24/outline';
import LatestVideos from '../components/latestVideos';
import { getTaarifaMatukio } from '../services/taarifaMatukioService';
import { getTaarifa } from '../services/TaarifaKwaUmmaService';
import { useTranslation } from "react-i18next";

// Import images
import mjumbe from '../assets/OIP.jpeg';
import mkurugenzi from '../assets/mkurugenzi.jpg';
import mwenyekiti from '../assets/mwenyekiti.jpg';
import mkiti from '../assets/m-kiti.jpg';
import juma from '../assets/juma.jpg';
import Awadh from '../assets/AWADH.jpg';
import ayoub from '../assets/AYOUB.jpg';
import halima from '../assets/HALIMA.jpg';
import idrisa from '../assets/IDRISSA.jpg';

// Commissioners data

// const commissioners = [
  
//   {
//     id: 1,
//     name: 'Mhe.  Jaji George Joseph Kazi',
//     title: 'Mwenyekiti',
//     description: 'Mwenyekiti wa Tume ya Uchaguzi Zanzibar',
//     image: mwenyekiti
//   },
//   {
//     id: 2,
//     name: 'Mhe. Jaji Aziza I Suwedi',
//     title: 'Makamo Mwenyekiti',
//     description: 'Makamo Mwenyekiti wa Tume ya Uchaguzi Zanzibar',
//     image: mkiti
//   },
//   {
//     id: 3,
//     name: 'Mhe. Idrisa',
//     title: 'Mjumbe',
//     description: 'Mjumbe wa Tume ya Uchaguzi Zanzibar',
//     image: idrisa
//   },
//   {
//     id: 4,
//     name: 'Mhe. Juma Haji Ussi',
//     title: 'Mjumbe',
//     description: 'Mjumbe wa Tume ya Uchaguzi Zanzibar',
//     image: juma
//   },
//   {
//     id: 5,
//     name: 'Mhe. Halima',
//     title: 'Mjumbe',
//     description: 'Mjumbe wa Tume ya Uchaguzi Zanzibar',
//     image: halima
//   },
//   {
//     id: 6,
//     name: 'Mhe. Ayoub',
//     title: 'Mjumbe',
//     description: 'Mjumbe wa Tume ya Uchaguzi Zanzibar',
//     image: ayoub
//   },
//   {
//     id: 7,
//     name: 'Mhe. Awadh',
//     title: 'Mjumbe',
//     description: 'Mjumbe wa Tume ya Uchaguzi Zanzibar',
//     image: Awadh
//   },
//   {
//     id: 8,
//     name: 'Ndg. Thabit Idarous Faina',
//     title: 'Mkurugenzi wa Uchaguzi',
//     description: 'Mkurugenzi wa Uchaguzi',
//     image: mkurugenzi
//   },
  
  
  
  
  
// ];

// BreadcrumbNav Component
const BreadcrumbNav = () => {
  const {t}= useTranslation();
  return (
    <>
      <Helmet>
        <title>ZEC | Wajumbe wa Tume</title>
      </Helmet>
      <div className="bg-white border-b border-gray-100 dark:bg-gray-800">
        <div className="max-w-6xl mx-auto py-3 px-4 dark:bg-gray-800">
          <div className="flex items-center space-x-2 text-gray-700">
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-colors">
              <HomeIcon className="w-5 h-5 dark:text-white" />
              <span className="ml-1.5 text-sm font-medium dark:text-white">{t('Mwanzo')}</span>
            </div>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-colors">
              <span className="text-sm font-medium dark:text-white">{t('Kuhusu Sisi')}</span>
            </div>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <span className="text-sm font-semibold text-yellow-600">{t('Wajumbe wa Tume')}</span>
          </div>
        </div>
      </div>
    </>
  );
};

// NewsCard Component
const NewsCard = ({ news }) => {
  return (
    <button className="w-full text-left group">
      <div className="flex gap-3 p-3 dark:bg-gray-800">
        <div className="relative w-16 h-16 flex-shrink-0 overflow-hidden rounded-lg">
          <img
            src={news.image}
            alt={news.title}
            className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
          />
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-sm text-gray-800 font-medium line-clamp-3 hover:text-yellow-600 group-hover:text-yellow-600 transition-colors duration-200 dark:text-white">
            {news.title}
          </p>
          <p className="text-sm text-gray-800 line-clamp-3 hover:text-yellow-600 group-hover:text-yellow-600 transition-colors duration-200 dark:text-white">
            {news.description}
          </p>
          <div className="text-xs text-gray-500 mt-1 dark:text-white">
            {news.date}
          </div>
        </div>
      </div>
    </button>
  );
};


// Sidebar Component
const Sidebar = () => {
  const {t}= useTranslation();
  const [newsItems, setNewsItems] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const habariMpya = await getTaarifaMatukio();
        const matangazo = await getTaarifa();

        setNewsItems(habariMpya.data.slice(0, 2));
        setAnnouncements(matangazo.data.slice(0, 2));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="sticky top-20">
      {/* News Section */}
      <div className="bg-white rounded-lg shadow-md mb-6">
        <div className="bg-yellow-500 px-4 py-3">
          <div className="flex items-center gap-2 text-white">
            <BellAlertIcon className="w-5 h-5" />
            <h2 className="text-lg font-semibold">{t('Habari Mpya')}</h2>
          </div>
        </div>
        <div className="divide-y divide-gray-100">
          {newsItems.map((news) => (
            <NewsCard key={news.id} news={news} />
          ))}
        </div>
      </div>

      {/* Latest Videos Component */}
      <LatestVideos />

      {/* Announcements Section */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden dark:bg-gray-800 mt-6">
        <div className="border-b border-gray-100 px-4 py-3">
          <div className="flex items-center gap-2 text-yellow-600">
            <NewspaperIcon className="w-5 h-5 dark:text-white" />
            <h2 className="text-lg font-semibold">{t('Matangazo')}</h2>
          </div>
        </div>
        <div className="p-4 space-y-3">
          {announcements.map((announcement, index) => (
            <a
              key={index}
              href={announcement.document}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-gray-700 px-2 py-1.5 rounded transition-colors group"
            >
              <ChevronRightIcon className="w-4 h-4 text-yellow-500 group-hover:text-yellow-600" />
              <span className="text-sm font-medium group-hover:text-yellow-600 dark:text-white">
                {announcement.description}
              </span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

// CommissionerCard Component
const CommissionerCard = ({ commissioner }) => {
  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden w-full max-w-2xl transform hover:-translate-y-1 hover:shadow-xl transition-all duration-300 dark:bg-gray-800">
      <div className="flex flex-col md:flex-row">
        <div className="relative md:w-1/3">
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent z-10" />
          <img
            src={commissioner.image}
            alt={commissioner.name}
            className="w-full h-64 md:h-full object-cover"
          />
        </div>
        <div className="md:w-2/3 p-6 bg-gradient-to-br from-white to-gray-50 dark:bg-gray-800">
          <h3 className="text-2xl font-bold text-gray-900 mb-2">{commissioner.name}</h3>
          <div className="w-20 h-1 bg-yellow-500 mb-4"></div>
          <p className="text-yellow-600 font-medium text-lg mb-4">{commissioner.title}</p>
          <p className="text-gray-600">{commissioner.description}</p>
          <div className="mt-6">
            <button className="px-6 py-2 bg-yellow-500 text-white rounded-lg text-sm font-medium hover:bg-yellow-600 transition-colors shadow-md hover:shadow-lg">
              Wasifu
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main Wajumbe Component
const Wajumbe = () => {
  const [t] = useTranslation();
  const commissioners = [
    {
      id: 1,
      name: 'Mhe.  Jaji George Joseph Kazi',
      title: t('Mwenyekiti'),
      description: t('Mwenyekiti wa Tume ya Uchaguzi Zanzibar'),
      image: mwenyekiti
    },
    {
      id: 2,
      name: 'Mhe. Jaji Aziza I Suwedi',
      title: t('Makamo Mwenyekiti'),
      description: t('Makamo Mwenyekiti wa Tume ya Uchaguzi Zanzibar'),
      image: mkiti
    },
    {
      id: 3,
      name: 'Mhe. Idrisa',
      title: t('Mjumbe'),
      description: t('Mjumbe wa Tume ya Uchaguzi Zanzibar'),
      image: idrisa
    },
    {
      id: 4,
      name: 'Mhe. Juma Haji Ussi',
      title: t('Mjumbe'),
      description: t('Mjumbe wa Tume ya Uchaguzi Zanzibar'),
      image: juma
    },
    {
      id: 5,
      name: 'Mhe. Halima',
      title: t('Mjumbe'),
      description: t('Mjumbe wa Tume ya Uchaguzi Zanzibar'),
      image: halima
    },
    {
      id: 6,
      name: 'Mhe. Ayoub',
      title: t('Mjumbe'),
      description: t('Mjumbe wa Tume ya Uchaguzi Zanzibar'),
      image: ayoub
    },
    {
      id: 7,
      name: 'Mhe. Awadh',
      title: t('Mjumbe'),
      description: t('Mjumbe wa Tume ya Uchaguzi Zanzibar'),
      image: Awadh
    },
    {
      id: 8,
      name: 'Ndg. Thabit Idarous Faina',
      title: t('Mkurugenzi wa Uchaguzi'),
      description: t('Mkurugenzi wa Uchaguzi'),
      image: mkurugenzi
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-800">
      <BreadcrumbNav />
      
      <div className="max-w-6xl mx-auto p-4 py-6 dark:bg-gray-800">
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Main Content */}
          <div className="lg:w-2/3">
            <div className="bg-white rounded-lg shadow-sm p-6 dark:bg-gray-800">
              <h1 className="text-2xl font-bold text-yellow-500 border-b-2 border-yellow-200 pb-3 mb-6 flex items-center gap-2">
                <UserGroupIcon className="w-6 h-6" />
                {t('WAJUMBE WA TUME YA UCHAGUZI ZANZIBAR')}
              </h1>
              
              <div className="flex flex-col items-center space-y-8 dark:bg-gray-800">
                {commissioners.map((commissioner) => (
                  <CommissionerCard key={commissioner.id} commissioner={commissioner} />
                ))}
              </div>
            </div>
          </div>
          
          {/* Sidebar */}
          <div className="lg:w-1/3 dark:bg-gray-800">
            <Sidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wajumbe;