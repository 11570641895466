import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { getTaarifaMatukio } from "../../services/taarifaMatukioService";
import { getTaarifa } from "../../services/TaarifaKwaUmmaService";
import LatestVideos from "../../components/latestVideos";
import { useTranslation } from 'react-i18next';
import {
    HomeIcon,
    ChevronRightIcon,
    BellAlertIcon,
    NewspaperIcon,
    ChevronRightIcon as ChevronRight,
} from "@heroicons/react/24/outline";

const NewsCard = ({ news }) => {
    return (
        <a href="#" className="block hover:bg-gray-50 transition-colors group">
            <div className="flex gap-3 p-2 dark:bg-gray-800">
                <div className="relative w-16 h-16 flex-shrink-0 overflow-hidden rounded">
                    <img
                        src={news.image}
                        alt={news.title}
                        className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300 dark:text-white"
                    />
                </div>
                <div className="flex-1 min-w-0 flex flex-col justify-between">
                    <p className="text-sm text-gray-800 font-medium line-clamp-3 group-hover:text-yellow-600 transition-colors dark:text-white">
                        {news.title}
                    </p>
                    <p className="text-gray-800 dark:text-white">{news.description}</p>
                    <div className="flex items-center text-xs text-gray-500 mt-0.5">
                        {news.date}
                    </div>
                </div>
            </div>
        </a>
    );
};

const NewsPage = () => {
    const {t}= useTranslation();
    const location = useLocation();
    const [newsData, setNewsData] = useState(location.state?.news || null);
    const [newsItems, setNewsItems] = useState([]);
    const [announcements, setAnnouncements] = useState([]);

    useEffect(() => {
        const fetchNews = async () => {
            if (!newsData) {
                try {
                    const response = await getTaarifaMatukio();
                    const fetchedNews = response.data.find(
                        (item) => item.id === location.state?.newsId
                    );
                    setNewsData(fetchedNews || response.data[0]);
                } catch (error) {
                    console.error("Error fetching news:", error);
                }
            }
        };
        fetchNews();
    }, [newsData, location.state?.newsId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const habariMpya = await getTaarifaMatukio();
                const matangazo = await getTaarifa();
                setNewsItems(habariMpya.data.slice(0, 2));
                setAnnouncements(matangazo.data.slice(0, 2));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    if (!newsData) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <Helmet>
                <title>ZEC | {newsData.title}</title>
            </Helmet>
            <div className="max-w-7xl mx-auto px-4 py-8">
                <div className="flex flex-col lg:flex-row gap-6">
                    {/* Main Content */}
                    <main className="w-full lg:w-3/4 order-1 lg:order-1">
                        <div className="bg-white rounded-lg shadow-md p-6 dark:bg-gray-800">
                            <div className="flex flex-col md:flex-row justify-between items-start mb-6">
                                <h1 className="text-2xl font-bold pr-4 flex-1 dark:text-white">
                                    {newsData.title}
                                </h1>
                                <span className="text-gray-600 text-sm mt-2 md:mt-0 dark:text-white">
                                    {newsData.date}
                                </span>
                            </div>

                            <div className="mb-6">
                                <img
                                    src={newsData.image}
                                    alt={newsData.title}
                                    className="w-full  h-auto rounded-lg"
                                />
                            </div>

                            <p className="text-gray-700 leading-relaxed dark:text-white">
                                {newsData.description}
                            </p>
                        </div>
                    </main>

                    {/* Sidebar */}
                    <aside className="w-full lg:w-1/4 order-2 lg:order-2">
                        <div className="sticky top-20 space-y-4">
                            <div className="bg-white rounded-lg shadow-sm overflow-hidden mb-4">
                                <div className="bg-yellow-500 px-2 py-3">
                                    <div className="flex items-center gap-2 text-white">
                                        <BellAlertIcon className="w-5 h-5" />
                                        <h2 className="text-lg font-semibold">{t('Habari Mpya')}</h2>
                                    </div>
                                </div>
                                <div className="divide-y divide-gray-100">
                                    {newsItems.map((news) => (
                                        <NewsCard key={news.id} news={news} />
                                    ))}
                                </div>
                            </div>

                            <LatestVideos />

                            <div className="bg-white rounded-lg shadow-sm overflow-hidden dark:bg-gray-800">
                                <div className="border-b border-gray-100 px-2 py-3">
                                    <div className="flex items-center gap-2 text-yellow-600">
                                        <NewspaperIcon className="w-5 h-5" />
                                        <h2 className="text-lg font-semibold">{t('Matangazo')}</h2>
                                    </div>
                                </div>
                                <div className="p-2 space-y-3">
                                    {announcements.map((announcement, index) => (
                                        <a
                                            key={index}
                                            href={announcement.document}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="flex items-center gap-2 text-gray-700 hover:text-yellow-600 transition-all duration-300 group transform hover:translate-x-2"
                                        >
                                            <ChevronRight className="w-4 h-4 text-yellow-500 group-hover:text-yellow-600" />
                                            <span className="text-sm font-medium dark:text-white">
                                                {announcement.description}
                                            </span>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </>
    );
};

export default NewsPage;