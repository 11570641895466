import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { 
  HomeIcon, 
  ChevronRightIcon,
  BellAlertIcon,
  NewspaperIcon,
  ChevronRightIcon as ChevronRight
} from '@heroicons/react/24/outline';
import LatestVideos from '../components/latestVideos';
import { getTaarifaMatukio } from '../services/taarifaMatukioService';
import { getTaarifa } from '../services/TaarifaKwaUmmaService';
import { useTranslation } from "react-i18next";

const BreadcrumbNav = () => {
  const {t}= useTranslation();
  return (
    <>
      <Helmet>
        <title>ZEC | Dira na Dhamira</title>
      </Helmet>
      <nav className="bg-white shadow-sm dark:bg-gray-800">
        <div className="max-w-6xl mx-auto py-4 px-4">
          <div className="flex items-center space-x-2 text-gray-700">
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105">
              <HomeIcon className="w-5 h-5 dark:text-white" />
              <span className="ml-1.5 text-sm font-medium dark:text-white">{t('Mwanzo')}</span>
            </div>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105">
              <span className="text-sm font-medium dark:text-white">{t('Kuhusu Sisi')}</span>
            </div>
            <ChevronRightIcon className="w-4 h-4" />
            <span className="text-sm font-semibold text-yellow-600 dark:text-white">{t('Dira na Dhamira')}</span>
          </div>
        </div>
      </nav>
    </>
  );
};

const NewsCard = ({ news }) => {
  return (
    <a href="#" className="block group">
      <div className="flex gap-4 p-4 transition-all duration-300 ease-in-out transform hover:translate-x-2 dark:bg-gray-800">
        <div className="relative w-20 h-20 flex-shrink-0 overflow-hidden rounded-lg shadow-md">
          <img
            src={news.image}
            alt={news.title}
            className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
          />
        </div>
        <div className="flex-1 min-w-0 flex flex-col justify-between">
          <p className="text-sm text-gray-800 font-medium line-clamp-3 group-hover:text-yellow-600 transition-colors duration-300 dark:text-white">
            {news.title}
          </p>
          <p className="text-sm text-gray-800  line-clamp-3 group-hover:text-yellow-600 transition-colors duration-300 dark:text-white">
            {news.description}
          </p>
          <div className="flex items-center text-xs text-gray-500 mt-2">
            {news.date}
          </div>
        </div>
      </div>
    </a>
  );
};

const DiraNaDhamira = () => {
  const {t}= useTranslation();
  const [newsItems, setNewsItems] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const habariMpya = await getTaarifaMatukio();
        const matangazo = await getTaarifa();

        setNewsItems(habariMpya.data.slice(0, 2));
        setAnnouncements(matangazo.data.slice(0, 2));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-800">
      <BreadcrumbNav />

      <div className="max-w-6xl mx-auto p-6 py-8 dark:bg-gray-800">
        <div className="flex flex-col lg:flex-row gap-8 dark:bg-gray-800">
          <div className="lg:w-2/3 dark:bg-gray-800">
            <div className="bg-white rounded-xl shadow-md p-8 transition-transform duration-300 hover:shadow-lg dark:bg-gray-800">
              <h1 className="text-2xl font-bold text-yellow-500 border-b-2 border-yellow-200 pb-3 mb-6">
                {t('DIRA NA DHAMIRA')}
              </h1>
              
              <div className="prose max-w-none space-y-8 dark:bg-gray-800">
                <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-all duration-300 dark:bg-gray-800">
                  <h2 className="text-xl font-semibold text-gray-900 mb-3 flex items-center border-b border-gray-100 pb-2">
                    <span className="w-1 h-6 bg-yellow-500 rounded-full mr-3"></span>
                    <span className="tracking-wide dark:text-white">{t('Dira')}</span>
                  </h2>
                  <p className="text-gray-700 leading-relaxed pl-4 border-l-2 border-yellow-100 dark:text-white">
                    Kuwa ni Taasisi inayojitegemea na inayoheshimika yenye uwezo wa kutosha wa kutoa huduma za Uchaguzi kwa weledi na ufanisi ambayo italeta imani kwa wadau. 
                  </p>
                </div>

                <div className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-all duration-300 dark:bg-gray-800">
                  <h2 className="text-xl font-semibold text-gray-900 mb-3 flex items-center border-b border-gray-100 pb-2">
                    <span className="w-1 h-6 bg-yellow-500 rounded-full mr-3"></span>
                    <span className="tracking-wide dark:text-white">{t('Dhamira')}</span>
                  </h2>
                  <p className="text-gray-700 leading-relaxed pl-4 border-l-2 border-yellow-100 dark:text-white">
                    Kutoa huduma za kiuchaguzi zinazoaminika kwa kufanya mapitio ya mipaka ya majimbo ya uchaguzi kwa wakati unaostahiki, Kuendeleza Daftari la Kudumu la Wapiga Kura, kuendesha elimu ya wapiga kura na kukuza dhana ya ushirikishwaji wa wadau katika shughuli za uchaguzi. 
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:w-1/3 space-y-6">
          <div className="sticky top-20">
            <div className="bg-white rounded-xl shadow-md overflow-hidden transition-transform duration-300 hover:shadow-lg dark:bg-gray-800">
              <div className="bg-yellow-500 px-4 py-3">
                <div className="flex items-center gap-2 text-white">
                  <BellAlertIcon className="w-5 h-5" />
                  <h2 className="text-lg font-semibold">{t('Habari Mpya')}</h2>
                </div>
              </div>
              
              <div className="divide-y divide-gray-100">
                {newsItems.map((news) => (
                  <NewsCard key={news.id} news={news} />
                ))}
              </div>
            </div>

            <LatestVideos />

            <div className="bg-white rounded-xl shadow-md overflow-hidden transition-transform duration-300 hover:shadow-lg dark:bg-gray-800 mt-6">
              <div className="border-b border-gray-100 px-6 py-4">
                <div className="flex items-center gap-3 text-yellow-600">
                  <NewspaperIcon className="w-6 h-6" />
                  <h2 className="text-xl font-semibold">{t('Matangazo')}</h2>
                </div>
              </div>
              
              <div className="p-6 space-y-4">
                {announcements.map((announcement, index) => (
                  <a
                    key={index}
                    href={announcement.document}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-3 text-gray-700 hover:text-yellow-600 transition-all duration-300 group transform hover:translate-x-2"
                  >
                    <ChevronRight className="w-5 h-5 text-yellow-500 group-hover:text-yellow-600" />
                    <span className="text-base font-medium dark:text-white">
                      {announcement.description}
                    </span>
                  </a>
                ))}
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiraNaDhamira;