import React, { useState, useEffect, useCallback } from "react";
import { getCarousels } from '../../services/carouselService';
import { getTaarifaMatukio } from '../../services/taarifaMatukioService';
import { useTranslation } from 'react-i18next';
import mwenyekiti from "../../assets/mwenyekiti (1).jpg";
import mkurugenzi from "../../assets/mkurugenzi.jpg";

const SplitLayout = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState([]);
  const [taarifaMatukio, setTaarifaMatukio] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pause, setPause] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [carouselResponse, taarifaResponse] = await Promise.all([
          getCarousels(),
          getTaarifaMatukio()
        ]);
  
        setSlides(carouselResponse?.data || []);
        setTaarifaMatukio(taarifaResponse?.data.slice(0, 3) || []);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  
  const nextSlide = useCallback(() => {
    if (slides.length > 0) {
      setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    }
  }, [slides]);
  
  const prevSlide = () => {
    if (slides.length > 0) {
      setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    }
  };
  

  useEffect(() => {
    if (!pause) {
      const timer = setInterval(nextSlide, 5000);
      return () => clearInterval(timer);
    }
  }, [nextSlide, pause]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!slides || slides.length === 0) return <div>No carousel items available</div>;
  

  return (
    
    <>
      <div className="w-full mx-auto xl:max-w-[1920px] 2xl:max-w-[2560px] 3xl:max-w-[3840px] 4xl:max-w-[5120px] 5xl:max-w-[6400px] 6xl:max-w-[7680px] px-0 flex flex-col lg:flex-row min-h-[400px] mt-2">
        {/* Left side - Slideshow */}
        <div 
          className="flex-grow lg:w-3/4 relative overflow-hidden min-h-[200px] h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px]"
          onMouseEnter={() => setPause(true)}
          onMouseLeave={() => setPause(false)}
          onTouchStart={() => setPause(true)}
          onTouchEnd={() => setPause(false)}
        >
          <div className="relative w-full h-full overflow-hidden">
            {slides.map((slide, index) => (
              <div
                key={slide.id}
                className={`absolute w-full h-full transition-opacity duration-500 ease-in-out ${
                  currentSlide === index ? "opacity-100" : "opacity-0"
                }`}
              >
                <div className="w-full h-full relative">
                  <img
                    src={slide.image}
                    alt={slide.description}
                    className="w-full h-full object-cover"
                    style={{ objectPosition: "center",
                      height:"800px",
                     }}
                  />

                  <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white p-2 sm:p-4">
                    <p className="text-xs sm:text-sm md:text-base lg:text-lg">
                      {slide.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Custom arrow navigation */}
          <button
            onClick={prevSlide}
            className="absolute left-4 top-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white w-8 h-8 rounded-full hover:bg-opacity-75 transition-all flex items-center justify-center"
            aria-label="Previous slide"
          >
            <span className="transform rotate-45 border-l-2 border-b-2 border-white w-2 h-2 -translate-x-0.5" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-4 top-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white w-8 h-8 rounded-full hover:bg-opacity-75 transition-all flex items-center justify-center"
            aria-label="Next slide"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>

          {/* Slide indicators */}
          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
            {slides.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                className={`w-2 h-2 rounded-full transition-all ${
                  currentSlide === index
                    ? "bg-white scale-110"
                    : "bg-white bg-opacity-50 hover:bg-opacity-75"
                }`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </div>

        {/* Right side - Content */}
        <div className="w-full lg:w-1/4 pt-2 bg-gray-50 dark:bg-gray-800 xl:max-w-[1920px] 2xl:max-w-[2560px] 3xl:max-w-[3840px] 4xl:max-w-[5120px] 5xl:max-w-[6400px] 6xl:max-w-[7680px]">
          <h4 className="text-2xl font-bold mb-4 text-center relative dark:text-white">
            {t('Viongozi wa Tume')}
            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full h-[1px] bg-yellow-500"></span>
          </h4>
          <div className="space-y-2">
            {/* 1st Leader */}
            <div className="flex flex-col items-center">
              <div className="w-44 h-44 rounded-full bg-blue-100 flex items-center justify-center mb-4 border border-yellow-500 overflow-hidden">
                <img
                  src={mwenyekiti}
                  alt="Waziri AM - Mwenyekiti"
                  className="w-40 h-40 rounded-full object-cover"
                />
              </div>
              <h3 className="text-lg font-semibold text-center dark:text-white">Mhe.  Jaji George Joseph Kazi</h3>
              <p className="text-sm text-gray-600 text-center dark:text-white">
                {t('M/KITI WA TUME')}
              </p>
            </div>

            {/* 2nd Leader */}
            <div className="flex flex-col items-center">
              <div className="w-44 h-44 rounded-full bg-blue-100 flex items-center justify-center mb-4 border border-yellow-500 overflow-hidden">
                <img
                  src={mkurugenzi}
                  alt="Waziri AM - Mwenyekiti"
                  className="w-40 h-40 rounded-full object-cover"
                />
              </div>
              <h3 className="text-lg font-semibold text-center dark:text-white">Ndg. Thabit Idarous Faina</h3>
              <p className="text-sm text-gray-600 text-center dark:text-white">{t('MKURUGENZI WA UCHAGUZI')}</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <style>
          {`
          @keyframes marquee {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }

          .animate-marquee {
            display: inline-block;
            animation: marquee 75s linear infinite;
          }
        `}
        </style>
        <div className="w-full mx-auto xl:max-w-[1920px] 2xl:max-w-[2560px] 3xl:max-w-[3840px] 4xl:max-w-[5120px] 5xl:max-w-[6400px] 6xl:max-w-[7680px]">
          <div className="bg-gradient-to-r from-yellow-600 to-gray-600 text-yellow-300 py-2 flex items-center overflow-hidden">
            {/* Static text */}
            <div className="flex-shrink-0 px-4 font-bold border-r-2 border-yellow-300">
              {t('TAARIFA MPYA')} :
            </div>

            {/* Marquee text */}
            <div className="flex overflow-hidden">
              <div className="animate-marquee whitespace-nowrap">
                {taarifaMatukio.map((taarifa, index) => (
                  <span key={index} className="mx-4 text-white">
                    {taarifa.description}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SplitLayout;