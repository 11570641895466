// import React, { useEffect, useState } from "react";
// import { getFomu } from "../../services/FomuMaalumu"; // axios call for Fomu Maalumu
// import { getTaarifaMatukio } from "../../services/taarifaMatukioService"; // axios call for Taarifa Matukio
// import { FileText } from "lucide-react";
// import { Link } from "react-router-dom"; // Import Link for routing
// import { useTranslation } from 'react-i18next';

// // Function to truncate content to 20 words


// const truncateContent = (content) => {
  
//   const words = content.split(" ");
//   if (words.length > 20) {
//     return words.slice(0, 20).join(" ") + "...";
//   }
//   return content;
// };

// const NewsCard = ({ id, imageUrl, title, content, date, author, news,  }) => (
  
//   <div className="bg-white rounded-lg shadow-md overflow-hidden dark:bg-gray-800 dark:text-white">
//     <img
//       src={imageUrl}
//       alt={title}
//       className="mx-auto object-cover"
//       style={{
//         height: "300px",
//         width: "100%",
//       }}
//     />
//     <div className="p-4">
//       <h3 className="text-lg font-bold mb-2">{title}</h3>
//       <p className="text-gray-600 mb-4 dark:text-white">{truncateContent(content)}</p>
//       <div className="flex justify-between items-center text-gray-500 text-sm">
//         <span className="dark:text-white">{date}</span>
//         <Link
//   to="/habari-zaidi"
//   state={{ news: {
//     id: id,
//     title: title,
//     description: content,
//     date: date,
//     image: imageUrl
//   }}}
//   className="text-primary-500 hover:text-yellow-500 hover:underline dark:text-yellow-500"
// >
//   {t('Soma Zaidi')}
// </Link>
//       </div>
//     </div>
//   </div>
// );

// const PublicationCard = ({ title, date, url }) => (
//   <div className="bg-white rounded-lg shadow-md p-4 dark:bg-gray-800 dark:text-white flex items-center space-x-4">
//     <FileText className="w-10 h-10 text-gray-400" />
//     <div className="flex-grow">
//       <a
//         href={url}
//         target="_blank"
//         rel="noopener noreferrer"
//         className="text-primary-500 hover:text-yellow-500 hover:underline dark:text-yellow-500"
//       >
//         <h3 className="text-lg font-bold">{title}</h3>
//         <div className="text-gray-500 text-sm">{date}</div>
//       </a>
//     </div>
//   </div>
// );

// const NewsAndEventsPage = () => {
//   const { t } = useTranslation();
//   const [newsData, setNewsData] = useState([]);
//   const [publicationsData, setPublicationsData] = useState([]);

//   useEffect(() => {
//     const fetchNews = async () => {
//       try {
//         const data = await getTaarifaMatukio();
//         setNewsData(data.data);
//       } catch (error) {
//         console.error("Error fetching news:", error);
//       }
//     };

//     fetchNews();
//   }, []);

//   useEffect(() => {
//     const fetchPublications = async () => {
//       try {
//         const data = await getFomu();
//         setPublicationsData(data.data);
//       } catch (error) {
//         console.error("Error fetching publications:", error);
//       }
//     };

//     fetchPublications();
//   }, []);

//   return (
//     <div className="w-full mx-auto xl:max-w-[1920px] 2xl:max-w-[2560px] 3xl:max-w-[3840px] 4xl:max-w-[5120px] 5xl:max-w-[6400px] 6xl:max-w-[7680px] p-4">
//       <div className="bg-white dark:bg-gray-800">
//         {/* Flex Layout for Large Screens */}
//         <div className="flex flex-col lg:flex-row gap-8">
//           {/* Habari na Matukio Section (65%) */}
//           <div className="flex-1 lg:flex-[0.65]">
//             <h2 className="text-2xl font-bold mb-6 pt-12 dark:text-white">
//               {t('Habari na Matukio')}
//             </h2>
//             <div className="border-b-4 border-yellow-500 mb-6 w-auto" />
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//               {newsData.slice(0, 4).map((news, index) => (
//                 <NewsCard
//                   key={index}
//                   imageUrl={news.image}
//                   title={news.title}
//                   content={news.description}
//                   date={news.date}
//                   author="pages/habari/habar-zaidi/:id"
//                 />
//               ))}
//             </div>
//           </div>

//           {/* Fomu Maalumu Section (35%) */}
//           <div className="flex-1 lg:flex-[0.35]">
//             <h2 className="text-2xl font-bold mb-6 pt-12 dark:text-white">
//               {t('Fomu Maalumu')}
//             </h2>
//             <div className="border-b-4 border-yellow-500 mb-6 w-auto" />
//             <div className="grid grid-cols-1 gap-4">
//               {publicationsData.slice(0, 7).map((publication, index) => (
//                 <PublicationCard
//                   key={index}
//                   title={publication.description}
//                   date={publication.date}
//                   url={publication.document}
//                 />
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default NewsAndEventsPage;


import React, { useEffect, useState } from "react";
import { getFomu } from "../../services/FomuMaalumu";
import { getTaarifaMatukio } from "../../services/taarifaMatukioService";
import { FileText } from "lucide-react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Function to truncate content to 20 words
const truncateContent = (content) => {
  if (!content) return '';
  const words = content.split(" ");
  if (words.length > 20) {
    return words.slice(0, 20).join(" ") + "...";
  }
  return content;
};

// Separated NewsCard component with its own translation hook
const NewsCard = ({ id, imageUrl, title, content, date, author }) => {
  const { t } = useTranslation(); // Each component instance gets its own translation hook
  
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden dark:bg-gray-800 dark:text-white">
      <img
        src={imageUrl}
        alt={title}
        className="mx-auto object-cover"
        style={{
          height: "300px",
          width: "100%",
        }}
      />
      <div className="p-4">
        <h3 className="text-lg font-bold mb-2">{title}</h3>
        <p className="text-gray-600 mb-4 dark:text-white">{truncateContent(content)}</p>
        <div className="flex justify-between items-center text-gray-500 text-sm">
          <span className="dark:text-white">{date}</span>
          <Link
            to="/habari-zaidi"
            state={{ 
              news: {
                id: id,
                title: title,
                description: content,
                date: date,
                image: imageUrl
              }
            }}
            className="text-primary-500 hover:text-yellow-500 hover:underline dark:text-yellow-500"
          >
            {t('Soma Zaidi')}
          </Link>
        </div>
      </div>
    </div>
  );
};

// Separated PublicationCard component
const PublicationCard = ({ title, date, url }) => (
  <div className="bg-white rounded-lg shadow-md p-4 dark:bg-gray-800 dark:text-white flex items-center space-x-4">
    <FileText className="w-10 h-10 text-gray-400" />
    <div className="flex-grow">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-primary-500 hover:text-yellow-500 hover:underline dark:text-yellow-500"
      >
        <h3 className="text-lg font-bold">{title}</h3>
        <div className="text-gray-500 text-sm">{date}</div>
      </a>
    </div>
  </div>
);

const NewsAndEventsPage = () => {
  const { t } = useTranslation();
  const [newsData, setNewsData] = useState([]);
  const [publicationsData, setPublicationsData] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await getTaarifaMatukio();
        setNewsData(data.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  useEffect(() => {
    const fetchPublications = async () => {
      try {
        const data = await getFomu();
        setPublicationsData(data.data);
      } catch (error) {
        console.error("Error fetching publications:", error);
      }
    };

    fetchPublications();
  }, []);

  return (
    <div className="w-full mx-auto xl:max-w-[1920px] 2xl:max-w-[2560px] 3xl:max-w-[3840px] 4xl:max-w-[5120px] 5xl:max-w-[6400px] 6xl:max-w-[7680px] p-4">
      <div className="bg-white dark:bg-gray-800">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Habari na Matukio Section */}
          <div className="flex-1 lg:flex-[0.65]">
            <h2 className="text-2xl font-bold mb-6 pt-12 dark:text-white">
              {t('Habari na Matukio')}
            </h2>
            <div className="border-b-4 border-yellow-500 mb-6 w-auto" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {newsData.slice(0, 4).map((news, index) => (
                <NewsCard
                  key={index}
                  id={news.id}
                  imageUrl={news.image}
                  title={news.title}
                  content={news.description}
                  date={news.date}
                  author="pages/habari/habar-zaidi/:id"
                />
              ))}
            </div>
          </div>

          {/* Fomu Maalumu Section */}
          <div className="flex-1 lg:flex-[0.35]">
            <h2 className="text-2xl font-bold mb-6 pt-12 dark:text-white">
              {t('Fomu Maalumu')}
            </h2>
            <div className="border-b-4 border-yellow-500 mb-6 w-auto" />
            <div className="grid grid-cols-1 gap-4">
              {publicationsData.slice(0, 7).map((publication, index) => (
                <PublicationCard
                  key={index}
                  title={publication.description}
                  date={publication.date}
                  url={publication.document}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsAndEventsPage;