// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

// i18n
//   .use(Backend) // Load translations using http
//   .use(LanguageDetector) // Detect user language
//   .use(initReactI18next) // Passes i18n instance to react-i18next
//   .init({
//     fallbackLng: 'en',
//     debug: true,
//     interpolation: {
//       escapeValue: false, // React already safes from xss
//     },
//     backend: {
//         loadPath: '/locales/{{lng}}/translation.json', // For public folder
//         // If your files are inside src, use the following:
//         loadPath: './locales/{{lng}}/translation.json',
//     },
//   });

// export default i18n;

// // import i18n from 'i18next';
// // import { initReactI18next } from 'react-i18next';
// // import translationEn from './locales/en/translation.json';
// // import translationSw from './locales/sw/translation.json';

// // const resources = {
// //   en: { translation: translationEn },
// //   sw: { translation: translationSw },
// // };

// // i18n
// //   .use(initReactI18next)
// //   .init({
// //     resources,
// //     lng: 'sw', // default language
// //     fallbackLng: 'sw',
// //     interpolation: { escapeValue: false },
// //   });

// // export default i18n;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'sw',
    debug: true,
    supportedLngs: ['sw', 'en'], // explicitly declare supported languages
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // use this if files are in public folder
    },
  });

export default i18n;