import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  HomeIcon,
  ChevronRightIcon,
  BellAlertIcon,
  NewspaperIcon,
  ArrowDownTrayIcon,
  ChevronRightIcon as ChevronRight,
} from "@heroicons/react/24/outline";
import { useTranslation } from 'react-i18next';
import { getTaarifaMatukio } from "../../services/taarifaMatukioService";
import { getTaarifa } from "../../services/TaarifaKwaUmmaService";
import LatestVideos from "../../components/latestVideos";
import habari1 from "../../assets/img01.jpg";
import habari2 from "../../assets/img02.jpg";
import { getMaadili } from "../../services/maadiliService"; // API service for Maadili

// Improved PDF Icon Component
const DocumentPDFIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    className="text-red-500"
  >
    <path
      d="M4 4v16a2 2 0 002 2h12a2 2 0 002-2V8.342a2 2 0 00-.602-1.43l-4.44-4.342A2 2 0 0013.56 2H6a2 2 0 00-2 2z"
      fill="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <text
      x="6.5"
      y="15"
      fill="currentColor"
      fontSize="6"
      fontWeight="bold"
      fontFamily="Arial, sans-serif"
    >
      PDF
    </text>
    <path
      d="M14 2v4a2 2 0 002 2h4"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);

// const newsItems = [
//   {
//     id: 1,
//     title:
//       "Mwenyekiti wa ZEC awataka watendaji wa vituo vya kupigia kura kufanya kazi kwa weledi",
//     date: "24 Dec, 2024",
//     image: habari1,
//   },
//   {
//     id: 2,
//     title:
//       "Mwenyekiti wa Tume ya Uchaguzi Zanzibar, azindua mafunzo kwa watendaji wa vituo",
//     date: "09 Dec, 2024",
//     image: habari2,
//   },
// ];

const MaadiliyaUchaguzi = () => {
  const {t} = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [newsItems, setNewsItems] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        // Fetch data from both APIs in parallel
        const [maadiliResponse, habariMpyaResponse, matangazoResponse] =
          await Promise.all([getMaadili(), getTaarifaMatukio(), getTaarifa()]);

        // Process Maadili data
        if (maadiliResponse.success) {
          const formattedDocuments = maadiliResponse.data.map((item) => ({
            id: item.id,
            title: item.description,
            date: new Date(item.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            }),
            documentUrl: item.document,
          }));
          setDocuments(formattedDocuments);
        } else {
          setError("Failed to fetch Maadili documents.");
        }

        // Process Habari Mpya data
        if (habariMpyaResponse.success) {
          setNewsItems(habariMpyaResponse.data.slice(0, 2));
        }

        // Process Matangazo data
        if (matangazoResponse.success) {
          setAnnouncements(matangazoResponse.data.slice(0, 2));
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  const handleDownload = (url) => {
    window.location.href = url; // Trigger download for the file
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Helmet>
        <title>ZEC | MIONGOZO ya Uchaguzi</title>
      </Helmet>
      <nav className="bg-white shadow-sm dark:bg-gray-800">
        <div className="max-w-6xl mx-auto py-4 px-4">
          <div className="flex items-center space-x-2 text-gray-700">
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105">
              <HomeIcon className="w-5 h-5 dark:text-white" />
              <span className="ml-1.5 text-sm font-medium dark:text-white">
                {t('Mwanzo')}
              </span>
            </div>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />

            <span className="ml-1.5 text-sm font-medium dark:text-white">
              {t('Machapisho')}
            </span>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <span className="text-sm font-semibold text-yellow-600">
             {t(' Miongozo ya Uchaguzi')}
            </span>
          </div>
        </div>
      </nav>

      {/* Content Section */}
      <div className="min-h-screen bg-gray-50 dark:bg-gray-800">
        <div className="max-w-6xl mx-auto p-6 py-8 grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Main Content */}
          <div className="lg:col-span-2 bg-white rounded-xl shadow-md p-8 hover:shadow-lg transition-transform duration-300 dark:bg-gray-800">
            <h1 className="text-2xl font-bold text-yellow-500 border-b-2 border-yellow-200 pb-3 mb-6">
              {t('MIONGOZO YA UCHAGUZI')}
            </h1>

            {/* Document List */}
            <div className="space-y-4 dark:bg-gray-800 dark:text-white">
              {loading ? (
                <div className="text-center text-yellow-600">Loading...</div>
              ) : error ? (
                <div className="text-center text-red-600">{error}</div>
              ) : documents.length === 0 ? (
                <div className="text-center text-gray-600 dark:text-white">
                  {t('Hakuna Taarifa kwa sasa')}
                </div>
              ) : (
                documents.map((doc) => (
                  <div
                    key={doc.id}
                    className="flex items-center p-4 border rounded-md hover:shadow-lg transition-shadow duration-300 dark:text-white"
                  >
                    {/* Icon */}
                    <div className="w-10 h-10 flex-shrink-0 flex items-center justify-center rounded bg-gray-100 text-gray-600">
                      <DocumentPDFIcon />
                    </div>

                    {/* Content */}
                    <div className="ml-4 flex-1">
                      <a
                        href={doc.documentUrl}
                        className="text-sm text-gray-800 font-medium hover:text-yellow-600 transition-colors duration-300 dark:text-white dark:hover:bg-yellow-300"
                        download
                        target="_blank" // Opens the document in a new tab
                        rel="noopener noreferrer" // Security best practice when using target="_blank"
                      >
                        {doc.title}
                      </a>
                      <div className="text-xs text-gray-500 mt-1 dark:text-white">
                        {doc.date}
                      </div>
                    </div>

                    {/* Download Button */}
                    <a
                      href={doc.documentUrl}
                      className="text-yellow-600 font-medium text-sm flex items-center gap-1 hover:text-yellow-700"
                      download
                    >
                      <ArrowDownTrayIcon className="w-5 h-5 text-yellow-600 hover:text-yellow-700" />
                      Pakua
                    </a>
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Sidebar */}
          <div className="space-y-6">
            {/* Latest News */}
            <div className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-transform duration-300 dark:bg-gray-800">
              <div className="bg-yellow-500 px-4 py-3">
                <div className="flex items-center gap-2 text-white">
                  <BellAlertIcon className="w-5 h-5" />
                  <h2 className="text-lg font-semibold">{t('Habari Mpya')}</h2>
                </div>
              </div>

              <div className="divide-y divide-gray-100">
                {newsItems.map((news) => (
                  <a
                    key={news.id}
                    href="#"
                    className="block hover:bg-gray-50 dark:hover:bg-yellow-300 transition-colors group dark:text-white"
                  >
                    <div className="flex gap-3 p-3">
                      <div className="relative w-16 h-16 flex-shrink-0 overflow-hidden rounded">
                        <img
                          src={news.image}
                          alt={news.title}
                          className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300 dark:text-white"
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm text-gray-800 font-medium line-clamp-3 dark:group-hover:text-yellow-600 group-hover:text-yellow-600 transition-colors dark:text-white">
                          {news.title}
                        </p>
                        <p className="text-gray-800 dark:text-white">
                          {news.description}
                        </p>
                        <div className="text-xs text-gray-500 mt-1 dark:text-white">
                          {news.date}
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
            <LatestVideos />
            <div className="bg-white rounded-lg shadow-sm overflow-hidden dark:bg-gray-800 mt-6">
              {/* Reduced padding from px-4 to px-2 */}
              <div className="border-b border-gray-100 px-2 py-3">
                <div className="flex items-center gap-2 text-yellow-600">
                  <NewspaperIcon className="w-5 h-5" />
                  <h2 className="text-lg font-semibold">{t('Matangazo')}</h2>
                </div>
              </div>
              {/* Reduced padding from p-4 to p-2 */}
              <div className="p-2 space-y-3">
                {announcements.map((announcement, index) => (
                  <a
                    key={index}
                    href={announcement.document}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 text-gray-700 hover:text-yellow-600 transition-all duration-300 group transform hover:translate-x-2"
                  >
                    <ChevronRight className="w-4 h-4 text-yellow-500 group-hover:text-yellow-600" />
                    <span className="text-sm font-medium dark:text-white">
                      {announcement.description}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MaadiliyaUchaguzi;
