import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getTaarifaMatukio } from "../../services/taarifaMatukioService";
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import {
  HomeIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";

const NewsItem = ({ id, image, date, title, description }) => (
  <div className="relative bg-white rounded-lg shadow-md overflow-hidden dark:bg-gray-800">
    <img src={image} alt={title} className="w-full h-48 object-cover" />
    <div className="p-4">
      <p className="text-xs text-gray-500 mb-2 dark:text-white">{date}</p>
      <h3 className="text-sm font-medium text-gray-800 leading-tight dark:text-white">
        {title}
      </h3>
      <p className="text-sm text-gray-800 leading-tight dark:text-white">
        {description}
      </p>
      <Link
        to="/habari-zaidi"
        state={{
          news: {
            id,
            title,
            description,
            date,
            image,
          },
        }}
        className="text-primary-500 hover:text-yellow-500 hover:underline dark:text-yellow-500 block mt-2"
      >
        Soma Zaidi
      </Link>
    </div>
  </div>
);

const NewsGrid = () => {
  const [t]= useTranslation();
  const [newsItems, setNewsItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await getTaarifaMatukio();
        if (response && response.success && Array.isArray(response.data)) {
          setNewsItems(response.data);
        } else {
          setNewsItems([]);
        }
      } catch (error) {
        console.error("Error fetching news items:", error);
      }
    };

    fetchNews();
  }, []);

  const totalPages = Math.ceil(newsItems.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = newsItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>ZEC | Habari na Matukio</title>
      </Helmet>
      <nav className="bg-white shadow-sm dark:bg-gray-800">
        <div className="max-w-6xl mx-auto py-4 px-4">
          <div className="flex items-center space-x-2 text-gray-700">
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105">
              <HomeIcon className="w-5 h-5 dark:text-white" />
              <span className="ml-1.5 text-sm font-medium dark:text-white">
                {t('Mwanzo')}
              </span>
            </div>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <span className="text-sm font-semibold text-yellow-600">
              {t('Habari na Matukio')}
            </span>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto p-6 dark:bg-gray-800">
        <h1 className="text-xl font-bold text-yellow-600 mb-6 border-b pb-2">
          {t('HABARI')}
        </h1>

        {currentItems.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 dark:bg-gray-800 dark:text-white">
            {currentItems.map((item, index) => (
              <NewsItem
                key={indexOfFirstItem + index}
                id={item.id}
                image={item.image}
                title={item.title}
                description={item.description}
                date={item.date}
              />
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-600 dark:text-gray-300 mt-6">
            Hakuna taarifa kwa sasa
          </p>
        )}

        {totalPages > 1 && currentItems.length > 0 && (
          <div className="flex justify-center items-center space-x-2 mt-8">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`px-3 py-1 rounded transition-colors duration-200 ${
                  page === currentPage
                    ? "bg-orange-600 text-white"
                    : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                }`}
              >
                {page}
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default NewsGrid;
