import React, { useState, useEffect, useRef } from "react";
import { getVideo } from "../../services/videoService";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const VideoGrid = () => {
  const { t } = useTranslation();
  const [videos, setVideos] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState(null);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await getVideo();
        const videoData = Array.isArray(response.data) ? response.data : [];
        setVideos(videoData);
      } catch (error) {
        console.error("Error fetching videos:", error);
        setVideos([]);
      }
    };

    fetchVideos();
  }, []);

  const openModal = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentVideoUrl(null);
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ 
        left: -scrollContainerRef.current.offsetWidth, 
        behavior: 'smooth' 
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ 
        left: scrollContainerRef.current.offsetWidth, 
        behavior: 'smooth' 
      });
    }
  };

  return (
    <div className="w-full mx-auto relative xl:max-w-[1920px] 2xl:max-w-[2560px] 3xl:max-w-[3840px] 4xl:max-w-[5120px] 5xl:max-w-[6400px] 6xl:max-w-[7680px]">
      <div className="py-8">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-black mb-2 dark:text-white">{t('Maktaba')}</h1>
          <div className="h-1 w-16 bg-yellow-400 mx-auto"></div>
        </div>

        <div className="relative w-full">
          {/* Left Arrow - Visible only on small screens */}
          <button 
            onClick={scrollLeft} 
            className="md:hidden absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-yellow-400 p-2 rounded-full shadow-lg"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>

          {/* Right Arrow - Visible only on small screens */}
          <button 
            onClick={scrollRight} 
            className="md:hidden absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-yellow-400 p-2 rounded-full shadow-lg"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>

          {/* Scrollable Container */}
          <div 
            ref={scrollContainerRef}
            className="flex md:grid md:grid-cols-5 overflow-x-auto md:overflow-visible scrollbar-hide space-x-4 px-12 md:px-0 pb-4"
            style={{ 
              scrollSnapType: 'x mandatory', 
              WebkitOverflowScrolling: 'touch' 
            }}
          >
            {videos.slice(0, 5).map((video, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-full md:w-auto snap-center md:snap-align-none"
                style={{ scrollSnapAlign: 'center' }}
              >
                <div className="relative group overflow-hidden rounded-lg shadow-lg bg-white dark:bg-gray-800">
                  <button onClick={() => openModal(video.link)} className="block w-full">
                    <img
                      src={video.image}
                      alt={video.description}
                      className="w-full h-52 object-cover rounded-t-lg"
                    />
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition duration-300">
                      <span className="bg-yellow-400 p-3 rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-8 w-8 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14.752 11.168l-4.704-2.708A1 1 0 009 9.026v5.856a1 1 0 001.048.998l4.704-2.708a1 1 0 00.048-1.732z"
                          />
                        </svg>
                      </span>
                    </div>
                  </button>

                  <div className="p-4">
                    <p className="text-sm font-medium text-gray-700 text-center dark:text-white">
                      {video.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="text-right mt-6">
          <Link to="/habari/maktaba-video">
            <button className="bg-blue-500 hover:bg-yellow-600 text-white px-6 py-2 rounded shadow-lg">
              {t('Angalia Zaidi')} →
            </button>
          </Link>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4">
          <div className="bg-white rounded-lg p-4 w-full max-w-4xl mx-auto">
            <div className="relative">
              <button
                onClick={closeModal}
                className="absolute -top-2 -right-2 text-2xl text-gray-600 bg-white rounded-full w-8 h-8 flex items-center justify-center shadow-lg"
              >
                ×
              </button>
              <iframe
                width="100%"
                height="315"
                src={currentVideoUrl.replace("watch?v=", "embed/")}
                frameBorder="0"
                allowFullScreen
                title="Video"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoGrid;