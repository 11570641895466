import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  HomeIcon,
  ChevronRightIcon,
  BellAlertIcon,
  NewspaperIcon,
  ChevronRightIcon as ChevronRight,
} from "@heroicons/react/24/outline";
import { useTranslation } from 'react-i18next';
import { getTaarifaMatukio } from "../../services/taarifaMatukioService";
import { getTaarifa } from "../../services/TaarifaKwaUmmaService";
import LatestVideos from "../../components/latestVideos";
import habari1 from "../../assets/img01.jpg";
import habari2 from "../../assets/img02.jpg";

// const newsItems = [
//   {
//     id: 1,
//     title: 'Mwenyekiti wa INEC awataka watendaji wa vituo vya kupigia kura kufanya kazi kwa weledi',
//     date: '24 Dec, 2024',
//     image: habari1
//   },
//   {
//     id: 2,
//     title: 'Mwenyekiti wa Tume Huru ya Taifa ya Uchaguzi azindua mafunzo kwa watendaji wa vituo',
//     date: '09 Dec, 2024',
//     image: habari2
//   }
// ];

const BreadcrumbNav = () => {
  const {t} = useTranslation();
  return (
    <>
      <Helmet>
        <title>ZEC | Mfumo wa Uchaguzi</title>
      </Helmet>
      <div className="bg-white border-b border-gray-100 dark:bg-gray-800">
        <div className="max-w-6xl mx-auto py-3 px-4">
          <div className="flex items-center space-x-2 text-gray-700">
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-colors">
              <HomeIcon className="w-5 h-5 dark:text-white" />
              <span className="ml-1.5 text-sm font-medium dark:text-white">
                {t('Mwanzo')}
              </span>
            </div>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-colors">
              <span className="text-sm font-medium dark:text-white">
                {t('Uchaguzi')}
              </span>
            </div>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <span className="text-sm font-semibold text-yellow-600">
              {t('Mfumo wa Uchaguzi')}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const NewsCard = ({ news }) => {
  return (
    <a
      href="#"
      className="block hover:bg-gray-50 transition-colors group dark:bg-gray-800"
    >
      <div className="flex gap-3 p-3">
        <div className="relative w-16 h-16 flex-shrink-0 overflow-hidden rounded">
          <img
            src={news.image}
            alt={news.title}
            className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
          />
        </div>
        <div className="flex-1 min-w-0 flex flex-col justify-between">
          <p className="text-sm text-gray-800 font-medium line-clamp-3 group-hover:text-yellow-600 transition-colors dark:text-white">
            {news.title}
          </p>
          <p className="text-sm text-gray-800  line-clamp-3 group-hover:text-yellow-600 transition-colors duration-300 dark:text-white">
            {news.description}
          </p>
          <div className="flex items-center text-xs text-gray-500 mt-1 dark:text-white">
            {news.date}
          </div>
        </div>
      </div>
    </a>
  );
};

const MfumowaUchaguzi = () => {
  const {t}= useTranslation();
  const [newsItems, setNewsItems] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const habariMpya = await getTaarifaMatukio();
        const matangazo = await getTaarifa();

        setNewsItems(habariMpya.data.slice(0, 2));
        setAnnouncements(matangazo.data.slice(0, 2));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-800">
      <BreadcrumbNav />

      {/* Main Content */}
      <div className="max-w-6xl mx-auto p-4 py-6">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Left Column - Main Article */}
          <div className="lg:w-2/3">
            <div className="bg-white rounded-lg shadow-sm p-6 dark:bg-gray-800">
              <h1 className="text-2xl font-bold text-yellow-500  border-b-2 border-yellow-200 pb-3 mb-6">
                {t('MFUMO WA UCHAGUZI')}
              </h1>

              <div className="prose max-w-none">
                <p className="text-gray-700 leading-relaxed dark:text-white">
                  Maelezo kuhusu Mfumo wa Uchaguzi Zanzibar yatakaa
                  hapa..............
                </p>
              </div>
            </div>
          </div>

          {/* Right Column - News Updates */}
          <div className="lg:w-1/3">
            {/* News Updates Section */}
            <div className="bg-white rounded-lg shadow-sm overflow-hidden mb-6 dark:bg-gray-800">
              <div className="bg-yellow-500 px-4 py-3">
                <div className="flex items-center gap-2 text-white">
                  <BellAlertIcon className="w-5 h-5" />
                  <h2 className="text-lg font-semibold">{t('Habari Mpya')}</h2>
                </div>
              </div>

              <div className="divide-y divide-gray-100 dark:text-white">
                {newsItems.map((news) => (
                  <NewsCard key={news.id} news={news} />
                ))}
              </div>
            </div>
            <LatestVideos />
            {/* Announcements Section */}
            <div className="bg-white rounded-xl shadow-md overflow-hidden transition-transform duration-300 hover:shadow-lg dark:bg-gray-800 mt-6">
              <div className="border-b border-gray-100 px-6 py-4">
                <div className="flex items-center gap-3 text-yellow-600">
                  <NewspaperIcon className="w-6 h-6" />
                  <h2 className="text-xl font-semibold">{t('Matangazo')}</h2>
                </div>
              </div>

              <div className="p-6 space-y-4">
                {announcements.map((announcement, index) => (
                  <a
                    key={index}
                    href={announcement.document}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-3 text-gray-700 hover:text-yellow-600 transition-all duration-300 group transform hover:translate-x-2"
                  >
                    <ChevronRight className="w-5 h-5 text-yellow-500 group-hover:text-yellow-600" />
                    <span className="text-base font-medium dark:text-white">
                      {announcement.description}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MfumowaUchaguzi;
