import React from "react";
import bgTume from "../assets/tume.JPG";
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import {
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="w-full  mx-auto xl:max-w-[1920px] 2xl:max-w-[2560px] 3xl:max-w-[3840px] 4xl:max-w-[5120px] 5xl:max-w-[6400px] 6xl:max-w-[7680px]">
      {/* Top Contact Bar with gray background */}
      <div className="bg-gray-900 py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="flex items-center">
              <div className="mr-3">
                <svg
                  className="w-6 h-6 text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>
              </div>
              <div>
                <p className="text-sm text-white">+255 22 286 46430</p>
                <p className="text-xs text-gray-400">Monday-Friday, 8am-5pm</p>
              </div>
            </div>

            <div className="flex items-center">
              <div className="mr-3">
                <svg
                  className="w-6 h-6 text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
              </div>
              <div>
                <p className="text-sm text-white">info@zec.go.tz</p>
                <p className="text-xs text-gray-400">Email to contact us</p>
              </div>
            </div>

            <div className="flex items-center">
              <div className="mr-3">
                <svg
                  className="w-6 h-6 text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                </svg>
              </div>
              <div>
                <p className="text-sm text-white">Tume ya Uchaguzi</p>
                <p className="text-xs text-gray-400">Zanzibar</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Yellow Separator Line */}
      <div className="h-px bg-yellow-500"></div>

      {/* Main Footer Content with background image and overlay */}
      <div
        className="relative py-16"
        style={{
          backgroundImage: `url(${bgTume})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-gray-900/80"></div>

        {/* Content */}
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Our Profile */}
            <div>
              <h3 className="text-lg font-semibold mb-4 text-white underline decoration-yellow-500">
                {t('Mawasiliano')}
              </h3>

              <ul className="space-y-4">
                <li className="flex items-center text-gray-300">
                  <PhoneIcon className="w-6 h-6 text-yellow-500 mr-2" />
                  <span>{t('Simu')}: +255 242 231 489</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <EnvelopeIcon className="w-6 h-6 text-yellow-500 mr-2" />
                  <span>info@zec.go.tz</span>
                </li>
                <li className="flex items-start text-gray-300">
                  <MapPinIcon className="w-6 h-6 text-yellow-500 mr-2" />
                  <span>
                    1 Barabara ya Dr. Hussein Mwinyi,
                    <br />
                    Maisara,
                    <br />
                    {t('S.L.P')} 1001.
                    <br />
                    71125 Mjini Magharibi,
                    <br />
                    Zanzibar.
                  </span>
                </li>
                <li className="flex items-center text-gray-300">
                  <ClockIcon className="w-6 h-6 text-yellow-500 mr-2" />
                  <span>
                    Monday-Friday, 8am-5pm;
                    <br />
                    Sunday Closed
                  </span>
                </li>
              </ul>
            </div>

            {/* Publications */}
            <div>
              <h3 className="text-lg font-semibold mb-4 text-white underline decoration-yellow-500">
                {t('Kurasa za Karibu')}
              </h3>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="text-gray-300 hover:text-yellow-300">
                    {t('Sheria na Marekebisho ya ZEC')}
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-300 hover:text-yellow-300">
                    {t('Sera ya ZEC')}
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-300 hover:text-yellow-300">
                    {t('Elimu ya Uchaguzi')}
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-300 hover:text-yellow-300">
                    {t('Mpango Mkakati wa ZEC')}
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-300 hover:text-yellow-300">
                    {t('Mkataba ya Huduma kwa Mteja')}
                  </a>
                </li>
                <li>
                  <a href="/faqs" className="text-gray-300 hover:text-yellow-300">
                    FAQs
                  </a>
                </li>
              </ul>
            </div>

            {/* Loan Application */}
            <div>
              <h3 className="text-lg font-semibold mb-4 text-white underline decoration-yellow-500">
                {t('Tovuti Mashuhuri')}
              </h3>
              <ul className="space-y-2">
                <li>
                  <a href="#" className="text-gray-300 hover:text-yellow-300">
                    {t('Ofisi ya Rais Ikulu')}
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-300 hover:text-yellow-300">
                    {t('Ofisi ya Makamu wa pili wa Rais')}
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-300 hover:text-yellow-300">
                    {t('Msajili wa Vyama vya Siasa')}
                  </a>
                </li>
                <li>
                  <a href="#" className="text-gray-300 hover:text-yellow-300">
                    {t('Baraza la wawakilishi')}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.inec.go.tz"
                    className="text-gray-300 hover:text-yellow-300"
                  >
                    {t('Tume Huru ya Taifa ya Uchaguzi')}
                  </a>
                </li>
              </ul>
            </div>

            {/* Social Media */}
            <div>
              <h3 className="text-lg font-semibold mb-4 text-white underline decoration-yellow-500">
                {t('Mitandao ya Kijamii')}
              </h3>
              <ul className="space-y-4">
                <li className="flex items-center text-gray-300">
                  <FaTwitter className="w-5 h-5 text-yellow-500 mr-2" />
                  <a
                    href="#"
                    className="hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter/X
                  </a>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaFacebook className="w-5 h-5 text-yellow-500 mr-2" />
                  <a
                    href="https://facebook.com/ZECpages"
                    className="hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaInstagram className="w-5 h-5 text-yellow-500 mr-2" />
                  <a
                    href="https://instagram.com/zec_zanzibar"
                    className="hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li className="flex items-center text-gray-300">
                  <FaYoutube className="w-5 h-5 text-yellow-500 mr-2" />
                  <a
                    href="https://youtube.com/@ZECONLINETV"
                    className="hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    YouTube
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Copyright Footer */}
      <div className="bg-gray-900 py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center text-sm text-gray-400">
            <p>
              &copy; {new Date().getFullYear()} ZEC. All rights reserved. |
              Zanzibar Election Commission
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
