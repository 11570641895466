import React, { useState, useEffect } from "react";
import { getTaarifa } from "../../services/TaarifaKwaUmmaService";
import backgroundImage from "../../assets/icon-democracy.png";
import logo2 from "../../assets/logo2.png";
import { useTranslation } from 'react-i18next';

function IntroductionSection() {
  const { t } = useTranslation();
  const [matangazo, setMatangazo] = useState([]);

  // Fetch the latest 3 matangazo on component mount
  useEffect(() => {
    const fetchMatangazo = async () => {
      try {
        const response = await getTaarifa(); // Fetch data
        if (response && response.data) {
          setMatangazo(response.data.slice(0, 3)); // Ensure data exists before slicing
        } else {
          setMatangazo([]); // Set an empty array if data is undefined
        }
      } catch (error) {
        console.error("Error fetching matangazo:", error);
        setMatangazo([]); // Set empty array in case of an error
      }
    };

    fetchMatangazo();
  }, []);

  // Function to handle document download
  const handleDownload = (documentUrl) => {
    const link = document.createElement("a");
    link.href = documentUrl;
    link.download = true;
    link.click();
  };

  return (
    <div className="w-full mx-auto mt-11 xl:max-w-[1920px] 2xl:max-w-[2560px] 3xl:max-w-[3840px] 4xl:max-w-[5120px] 5xl:max-w-[6400px] 6xl:max-w-[7680px]">
      <div className="w-full bg-white text-black dark:bg-gray-800">
        <div className="mx-auto h-full lg:mx-4">
          {" "}
          {/* Add margin here */}
          <div className="px-0 md:px-4 lg:px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 dark:bg-gray-800">
              <div className="border-l-4 border-yellow-500 pl-4">
                <h1 className="text-3xl font-bold text-yellow-500 mb-4 pt-8">
                  {t('Karibu Tume ya Uchaguzi')}
                </h1>
                <p className="text-gray-700 italic dark:text-white">
                {t(`Tunajivunia kuwa mstari wa mbele katika kusimamia uchaguzi huru, wa haki, na uwazi kwa ajili ya kuhakikisha sauti ya kila mwananchi inasikika na kuchangia mustakabali wa taifa letu.`)}
                </p>
              </div>

              <div className="text-center flex items-center justify-center dark:text-white">
                <img
                  src={backgroundImage}
                  alt="HESLB Logo"
                  className="max-w-full h-auto transition-all duration-300 ease-in-out dark:filter dark:invert"
                />
              </div>

              <div className="flex justify-center md:justify-start">
                <div className="animate-pulse hover:animate-none bg-gradient-to-r from-gray-400 via-white to-yellow-200 text-center justify-center w-48 h-54 rounded-sm shadow-md flex flex-col items-center transition-all duration-300">
                  <img
                    src={logo2}
                    alt="BTRA Logo"
                    className="w-24 h-24 mt-4 mb-2"
                  />
                  <h2 className="text-gray-950 text-xl text-center font-semibold mb-2 pl-2">
                    ZEC OBSERVERS ONLINE PORTAL
                  </h2>
                  <div className="flex items-center">
                    <a
                      href="https://oms.zec.go.tz"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white hover:text-blue-200 flex items-center"
                    >
                      <span className="font-bold text-gray-950">
                        Click here
                      </span>
                      <svg
                        className="w-5 font-bold h-5 ml-1 text-gray-950"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>

              <div className="bg-white shadow-lg rounded-lg p-6 dark:bg-gray-800">
                <h1 className="text-2xl font-bold text-yellow-500 border-b-2 border-yellow-600 pb-2 mb-4">
                  {t('Matangazo')}
                </h1>

                <div className="space-y-4">
                  {matangazo.length > 0 ? (
                    matangazo.map((item) => (
                      <div
                        className="flex items-center space-x-3"
                        key={item.id}
                      >
                        <span className="bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                          NEW!
                        </span>
                        <p
                          className="text-green-700 font-medium dark:text-white cursor-pointer hover:text-yellow-600"
                          onClick={() => handleDownload(item.document)}
                        >
                          {item.description}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-500 dark:text-white">
                      {t('Hakuna matangazo kwa sasa.')}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroductionSection;
