import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://zec.go.tz/admin_panel/public/api/',
    //  baseURL: 'http://localhost:8000/api',
    //  baseURL: 'https://login.mwandishiaslam.com/api',
    
    headers: {
        'Content-Type': 'application/json',
    }
});

export default instance;