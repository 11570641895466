import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { 
  HomeIcon, 
  ChevronRightIcon,
  BellAlertIcon,
  NewspaperIcon,
  UserGroupIcon,
  ChartBarIcon,
  DocumentTextIcon
} from '@heroicons/react/24/outline';
import LatestVideos from '../components/latestVideos';
import { getTaarifaMatukio } from '../services/taarifaMatukioService';
import { getTaarifa } from '../services/TaarifaKwaUmmaService';
import utawala from '../assets/utawala.jpg';
import { useTranslation } from "react-i18next";

const muundoTume = {
  
  uongozi: {
    title: "MUUNDO WA TUME YA UCHAGUZI YA ZANZIBAR",
    description: [
      "Muundo wa Tume ya Uchaguzi ya Zanzibar umewekwa kwa mujibu wa kifungu cha119(1) cha Katiba ya Zanzibar ya mwaka 1984 kama ifuatavyo:-",
      "Mwenyekiti wa Tume ya Uchaguzi ambaye huteuliwa na Rais kutoka miongoni mwa watu wenye sifa za Jaji wa Mahakama Kuu au Mahakama ya Rufaa katika nchi iliyo mwanachama wa Jumuiya ya Madola au Mtu anayeheshimika katika Jamii.",
      "Wajumbe wawili watakaoteuliwa na Rais kufuatana na mapendekezo ya Kiongozi wa shughuli za Serikali katika Baraza la Wawakilishi (BLW).",
      "Wajumbe wawili watakaoteuliwa na Rais kufuatana na mapendekezo ya Kiongozi wa Upinzani katika Baraza la Wawakilishi (BLW) na iwapo hakuna kiongozi wa Upinzani kwa kushauriana na vyama vya siasa.",
      "Mjumbe mmoja atakayeteuliwa na Rais kutoka miongoni mwa Majaji wa Mahakama Kuu.",
      "Mjumbe mmoja atakayeteuliwa na Rais kama atakavyoona inafaa.",
      "Aidha, kwa mujibu wa kifungu cha 119(2)(b) Makamu Mwenyekiti wa Tume ya Uchaguzi huteuliwa na Tume kutoka miongoni mwa Wajumbe wa Tume.",
      "Tume inatekeleza majukumu yake ikiwa ni idara inayojitegemea na Mtendaji Mkuu wake ni Mkurugenzi wa Uchaguzi ambaye ndiye Katibu wa Tume kama ilivyoainishwa Akidi ya Kikao cha Tume ya Uchaguzi ni Wajumbe watano na uamuzi juu ya jambo lolote linalohusiana na uendeshaji wa majukumu ya Tume ya Uchaguzi unatakiwa uungwe mkono na Wajumbe walio wengi kama ilivyoelekezwa katika kifungu cha 119(10) cha Katiba ya Zanzibar ya mwaka 1984."
    ]
  }
};

const BreadcrumbNav = () => {
  const {t} = useTranslation();
  return (
    <>
      <Helmet>
        <title>ZEC | Muundo wa Tume</title>
      </Helmet>
      <nav className="bg-white shadow-sm dark:bg-gray-800">
        <div className="max-w-6xl mx-auto py-4 px-4">
          <div className="flex items-center space-x-2 text-gray-700">
            <button className="flex items-center hover:text-yellow-600 transition-all duration-300">
              <HomeIcon className="w-5 h-5 dark:text-white" />
              <span className="ml-1.5 text-sm font-medium dark:text-white">{t('Mwanzo')}</span>
            </button>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <button className="flex items-center hover:text-yellow-600 transition-all duration-300 dark:text-white">
              <span className="text-sm font-medium">{t('Kuhusu Sisi')}</span>
            </button>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <span className="text-sm font-semibold text-yellow-600">{t('Muundo wa Tume')}</span>
          </div>
        </div>
      </nav>
    </>
  );
};

const NewsCard = ({ news }) => {
  return (
    <button className="w-full text-left group">
      <div className="flex gap-3 p-3 dark:bg-gray-800">
        <div className="relative w-16 h-16 flex-shrink-0 overflow-hidden rounded-lg">
          <img
            src={news.image}
            alt={news.title}
            className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
          />
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-sm text-gray-800 font-medium line-clamp-3 hover:text-yellow-600 group-hover:text-yellow-600 transition-colors duration-200 dark:text-white">
            {news.title}
          </p>
          <p className="text-sm text-gray-800 line-clamp-3 hover:text-yellow-600 group-hover:text-yellow-600 transition-colors duration-200 dark:text-white">
            {news.description}
          </p>
          <div className="text-xs text-gray-500 mt-1 dark:text-white">
            {news.date}
          </div>
        </div>
      </div>
    </button>
  );
};

const OrganizationalSection = ({ section }) => {
  return (
    <div className="bg-white rounded-lg p-6 hover:shadow-lg transition-shadow duration-300 dark:bg-gray-800">
      <h2 className="text-xl font-bold text-gray-800 mb-4 border-b border-yellow-200 pb-2 dark:text-white">
        {section.title}
      </h2>
      <div className="space-y-4 dark:text-white">
        {section.description.map((paragraph, index) => (
          <p key={index} className="text-gray-600 dark:text-white">
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  );
};

const Sidebar = () => {
  const {t} = useTranslation();
  const [newsItems, setNewsItems] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const habariMpya = await getTaarifaMatukio();
        const matangazo = await getTaarifa();

        setNewsItems(habariMpya.data.slice(0, 2));
        setAnnouncements(matangazo.data.slice(0, 2));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="sticky top-20">
      <div className="bg-white rounded-lg shadow-md mb-6">
        <div className="bg-yellow-500 px-4 py-3">
          <div className="flex items-center gap-2 text-white">
            <BellAlertIcon className="w-5 h-5" />
            <h2 className="text-lg font-semibold">{t('Habari Mpya')}</h2>
          </div>
        </div>
        
        <div className="divide-y divide-gray-100">
          {newsItems.map((news) => (
            <NewsCard key={news.id} news={news} />
          ))}
        </div>
      </div>
      <LatestVideos />
      <div className="bg-white rounded-lg shadow-md overflow-hidden dark:bg-gray-800 mt-6">
        <div className="border-b border-gray-100 px-4 py-3">
          <div className="flex items-center gap-2 text-yellow-600">
            <NewspaperIcon className="w-5 h-5 dark:text-white" />
            <h2 className="text-lg font-semibold">{t('Matangazo')}</h2>
          </div>
        </div>
        <div className="p-4 space-y-3">
          {announcements.map((announcement, index) => (
            <a
              key={index}
              href={announcement.document}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-gray-700 px-2 py-1.5 rounded transition-colors group"
            >
              <ChevronRightIcon className="w-4 h-4 text-yellow-500 group-hover:text-yellow-600" />
              <span className="text-sm font-medium group-hover:text-yellow-600 dark:text-white">
                {announcement.description}
              </span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

const MuundoWaTume = () => {
  const {t} = useTranslation();
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-800">
      <BreadcrumbNav />
      <div className="relative">
        <div className="max-w-6xl mx-auto px-4 py-8">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="lg:w-2/3">
              <div className="bg-white rounded-xl shadow-md p-8 dark:bg-gray-800">
                <h1 className="text-2xl font-bold text-yellow-500 border-b-2 border-yellow-200 pb-3 mb-6 dark:text-white">
                  {t('MUUNDO WA TUME')}
                </h1>
                <div className="space-y-8 dark:text-white">
                  <OrganizationalSection section={muundoTume.uongozi} />
                  <img src={utawala} alt="Utawala" className="rounded-lg shadow-lg mt-4 dark:filter dark:invert" />
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 dark:bg-gray-800 ">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MuundoWaTume;