import React, { useState, useEffect } from "react";
import { Map, Users, ClipboardList, CheckCircle } from "lucide-react";
import { useInView } from "react-intersection-observer";
import { getTakwimu } from "../../services/takwimuService";
import { useTranslation } from 'react-i18next';

const StatisticsSection = () => {
  const { t } = useTranslation();
  const [statisticsData, setStatisticsData] = useState(null);
  const [counts, setCounts] = useState([0, 0, 0, 0]);
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTakwimu();
        if (response.success && response.data && response.data.length > 0) {
          // Get the first (and only) data object
          const data = response.data[0];
          setStatisticsData([
            parseInt(data.majimbo),
            parseInt(data.wadi),
            parseInt(data.vituo),
            parseInt(data.waliojiandikisha)
          ]);
        }
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    fetchData();
  }, []);

  const stats = [
    {
      id: 1,
      Icon: Map,
      title: t("Majimbo"),
      value: statisticsData ? statisticsData[0].toString() : "0",
    },
    {
      id: 2,
      Icon: ClipboardList,
      title: t("Wadi"),
      value: statisticsData ? statisticsData[1].toString() : "0",
    },
    {
      id: 3,
      Icon: CheckCircle,
      title: t("Vituo vya Kuandikisha"),
      value: statisticsData ? statisticsData[2].toString() : "0",
    },
    {
      id: 4,
      Icon: Users,
      title: t("Waliojiandikisha"),
      value: statisticsData ? statisticsData[3].toString() : "0",
    },
  ];

  useEffect(() => {
    if (inView && statisticsData) {
      stats.forEach((stat, index) => {
        const target = parseInt(stat.value);
        let current = 0;
        const step = Math.ceil(target / 50);
        const timer = setInterval(() => {
          current += step;
          if (current >= target) {
            current = target;
            clearInterval(timer);
          }
          setCounts(prev => {
            const newCounts = [...prev];
            newCounts[index] = current;
            return newCounts;
          });
        }, 40);

        return () => clearInterval(timer);
      });
    }
  }, [inView, statisticsData]);

  return (
    <>
    
    <div className="w-full  mx-auto mt-4 xl:max-w-[1920px] 2xl:max-w-[2560px] 3xl:max-w-[3840px] 4xl:max-w-[5120px] 5xl:max-w-[6400px] 6xl:max-w-[7680px]">
    <section className="relative bg-gradient-to-br from-gray-100 to-yellow-300 text-white pb-10 dark:bg-gray-800">
      <svg
        className="absolute inset-x-0 bottom-0"
        viewBox="0 0 1440 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#ffffff"
          d="M0,40 C200,80 400,20 720,40 C1000,60 1200,80 1440,40 L1440,100 L0,100 Z"
        />
      </svg>

      <div className="relative  mx-auto px-4 ">
        <h2 className="text-3xl font-bold text-yellow-500 mb-8 dark:text-gray-950">{t('Takwimu')}</h2>

        <div 
          ref={ref} 
          className="grid grid-cols-2 lg:grid-cols-4 gap-y-8 gap-x-4"
        >
          {stats.map(({ id, Icon, title }, index) => (
            <div
              key={id}
              className="relative text-center flex flex-col items-center"
            >
              <div className="flex justify-center items-center mb-4">
                <Icon className="w-12 h-12 text-yellow-400" />
              </div>
              <h3 className="text-lg text-black font-semibold">{title}</h3>
              <p className="text-2xl text-black font-bold mt-2">
                {counts[index].toLocaleString()}
              </p>
              {index % 4 !== 3 && (
                <div className="hidden lg:block absolute right-0 top-1/2 -translate-y-1/2 h-16 w-px bg-gray-300"></div>
              )}
              {index % 2 === 0 && index < stats.length - 1 && (
                <div className="lg:hidden absolute right-0 top-1/2 -translate-y-1/2 h-16 w-px bg-gray-300"></div>
              )}
            </div>
          ))}
        </div>

        <div className="text-right mt-6">
          <button className="bg-blue-500 hover:bg-yellow-600 text-white px-6 py-2 rounded shadow-lg dark:bg-yellow-400">
            {t('Angalia Zaidi')} →
          </button>
        </div>
      </div>
    </section>
    </div>
    </>
  );
};

export default StatisticsSection;