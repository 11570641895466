import axios from './axios';

const getCarousels = async () => {
    try {
        const response = await axios.get('/carousels');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export {
    getCarousels
};