import React, { useState, useEffect } from "react";
import logo2 from "../assets/zec-modified.png";

const FloatingBadge = () => {
  const [isRotating, setIsRotating] = useState(true);
  const [showStars, setShowStars] = useState(false);

  useEffect(() => {
    // Rotation interval
    const rotationInterval = setInterval(() => {
      setIsRotating((prev) => !prev);
    }, 5000);

    // Stars interval - shows stars every 5 seconds
    const starsInterval = setInterval(() => {
      setShowStars(true);
      setTimeout(() => setShowStars(false), 1000);
    }, 5000);

    return () => {
      clearInterval(rotationInterval);
      clearInterval(starsInterval);
    };
  }, []);

  const handleTouch = () => {
    setShowStars(true);
    setTimeout(() => setShowStars(false), 1000);
  };

  return (
    <div
      className="fixed animate-bounce hover:animate-none bottom-4 right-4 flex flex-col items-center z-50 transform scale-[var(--zoom-scale)] origin-bottom-right"
      style={{
        "--zoom-scale": "1", // Default zoom scale
      }}
    >
      <div className="relative">
        {showStars && (
          <>
            <div className="absolute -top-4 -left-4 text-yellow-400 text-2xl animate-ping">✦</div>
            <div className="absolute -top-4 right-0 text-yellow-400 text-2xl animate-ping">✦</div>
            <div className="absolute -bottom-4 -left-4 text-yellow-400 text-2xl animate-ping">✦</div>
            <div className="absolute -bottom-4 right-0 text-yellow-400 text-2xl animate-ping">✦</div>
            <div className="absolute top-0 -right-4 text-yellow-400 text-2xl animate-ping">✦</div>
            <div className="absolute top-0 -left-4 text-yellow-400 text-2xl animate-ping">✦</div>
            <div className="absolute bottom-0 -right-4 text-yellow-400 text-2xl animate-ping">✦</div>
            <div className="absolute bottom-0 -left-4 text-yellow-400 text-2xl animate-ping">✦</div>
          </>
        )}
       <img
  src={logo2}
  alt="Tume ya Uchaguzi"
  className={`
    w-16 sm:w-20 md:w-24 lg:w-32 
    h-16 sm:h-20 md:h-24 lg:h-32 
    rounded-full shadow-lg mb-2 
    cursor-pointer transition-transform duration-1000 
    ${isRotating ? "animate-[wiggle_2s_ease-in-out_infinite]" : ""}
  `}
  onClick={handleTouch}
  onTouchStart={handleTouch}
/>
      </div>
      <div className="bg-gradient-to-r from-gray-300 via-white to-yellow-300 text-gray-950 text-center p-3 rounded-lg shadow-lg max-w-xs">
        <p className="text-sm font-medium">
          "Kura haki yako, Amani wajibu wako."
        </p>
      </div>
    </div>
  );
};

const customStyles = `
  @keyframes wiggle {
    0%, 100% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(30deg);
    }
    75% {
      transform: rotate(-30deg);
    }
  }

  @keyframes bounce-slow {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  .animate-bounce-slow {
    animation: bounce-slow 2s infinite;
  }
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = customStyles;
document.head.appendChild(styleSheet);

export default FloatingBadge;
