import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronDown, Menu, X } from 'lucide-react';
import LanguageSwitcher from './LanguageSwitcher';
import DarkModeToggle from './DarkModeToggle';

const NavBar = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navRef = useRef(null);
  const dropdownTimeoutRef = useRef(null);

  const navLinks = [
    { title:  t('Nyumbani'), path: '/', dropdown: [] },
    {
      title: t('Kuhusu Sisi'),
      path: '/about',
      dropdown: [
        { title: t('Historia'), path: '/about/history' },
        { title: t('Wajumbe wa Tume'), path: '/about/wajumbe' },
        { title: t('Dira na Dhamira'), path: '/about/dira-dhima' },
        { title: t('Maadili ya Msingi'), path: '/about/maadili-ya-msingi' },
        // { title: 'Utawala', path: '/about/utawala' },
        { title: t('Muundo wa Tume'), path: '/about/muundo-wa-tume' },
      ]
    },
    {
      title: t('Uchaguzi'),
      path: '/uchaguzi',
      dropdown: [
        { title: t('Mfumo wa Uchaguzi'), path: '/uchaguzi/mfumo-uchaguzi' },
        { title: t('Mzunguko wa Uchaguzi'), path: '/uchaguzi/mzunguko-uchaguzi' },
        { title: t('Matokeo ya Uchaguzi'), path: '/uchaguzi/matokeo-uchaguzi' },
        { title: t('Taarifa za Uchaguzi'), path: '/uchaguzi/taarifa-uchaguzi' },
        { title: t('Elimu ya Mpiga kura'), path: '/uchaguzi/elimu-kura' }
      ]
    },
    {
      title: t('Habari na Matukio'),
      path: '/habari',
      dropdown: [
        { title: t('Habari'), path: '/habari/matukio' },
        { title: t('Taarifa kwa Umma'), path: '/habari/matangazo' },
        { title: t('Hotuba'), path: '/habari/hotuba' },
        { title: t('Maktaba ya Picha'), path: '/habari/maktaba-picha' },
        { title: t('Maktaba ya Video'), path: '/habari/maktaba-video' },
      ]
    },
    
    {
      title: t('Machapisho'),
      path: '/machapisho',
      dropdown: [
        { title: t('Sheria za Uchaguzi'), path: '/machapisho/sheria' },
        { title: t('Kanuni za Uchaguzi'), path: '/machapisho/kanuni' },
        { title: t('Miongozo ya Uchaguzi'), path: '/machapisho/maadili' },
        { title: t('Jarida la Tume'), path: '/machapisho/jarida' },
        { title: t('Fomu Maalumu'), path: '/machapisho/fomu-maalumu' }
      ]
    },
    { title: t('Wasiliana Nasi'), path: '/contact', dropdown: [] }
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownClick = (index, event) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const handleMouseEnter = (index) => {
    if (dropdownTimeoutRef.current) {
      clearTimeout(dropdownTimeoutRef.current);
    }
    setActiveDropdown(index);
  };

  const handleMouseLeave = () => {
    dropdownTimeoutRef.current = setTimeout(() => {
      setActiveDropdown(null);
    }, 100);
  };

  const handleMenuMouseEnter = () => {
    if (dropdownTimeoutRef.current) {
      clearTimeout(dropdownTimeoutRef.current);
    }
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
    setActiveDropdown(null);
  };

  return (
    <div className="w-full  mx-auto sticky top-0 z-50 xl:max-w-[1920px] 2xl:max-w-[2560px] 3xl:max-w-[3840px] 4xl:max-w-[5120px] 5xl:max-w-[6400px] 6xl:max-w-[7680px]">
    <div className="sticky top-0 z-50">
      <nav className="bg-white border-b border-yellow-200 shadow-sm dark:bg-gray-800 dark:text-white" ref={navRef} >
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            {/* Desktop Navigation */}
            <div className="hidden md:flex flex-1 justify-center space-x-4 dark:text-white">
              {navLinks.map((link, index) => (
                <div 
                  key={index} 
                  className="relative"
                  onMouseLeave={handleMouseLeave}
                >
                  {link.dropdown.length > 0 ? (
                    <div>
                      <button
                        onClick={(e) => handleDropdownClick(index, e)}
                        onMouseEnter={() => handleMouseEnter(index)}
                        className="px-3 py-2 text-gray-700 hover:text-yellow-600 rounded-md flex items-center text-sm font-medium transition-colors duration-200 ease-in-out dark:text-white"
                      >
                        {link.title}
                        <ChevronDown
                          className={`ml-1 h-4 w-4 transition-transform duration-200 ${
                            activeDropdown === index ? 'rotate-180 text-yellow-600' : ''
                          }`}
                        />
                      </button>
                      {activeDropdown === index && (
                        <div 
                          className="absolute left-0 mt-1 w-56 bg-white rounded-md shadow-lg py-1 z-50 "
                          onMouseEnter={handleMenuMouseEnter}
                        >
                          {link.dropdown.map((item, idx) => (
                            <Link
                              key={idx}
                              to={item.path}
                              onClick={handleLinkClick}
                              className="block px-4 py-2 text-sm text-gray-600 hover:bg-yellow-50 hover:text-yellow-600 transition-colors duration-150 dark:text-white dark:bg-gray-800"
                            >
                              {item.title}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link
                      to={link.path}
                      onClick={handleLinkClick}
                      className="px-3 py-2 text-gray-700 hover:text-yellow-600 rounded-md block text-sm font-medium transition-colors duration-200 ease-in-out dark:text-white"
                    >
                      {link.title}
                    </Link>
                  )}
                </div>
              ))}
            </div>

            {/* Mobile menu button */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden text-gray-700 p-2 rounded-md hover:bg-yellow-50 transition-colors duration-200 focus:outline-none dark:text-yellow-600"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>

          {/* Mobile Navigation */}
          <div
            className={`md:hidden transition-all duration-300 ease-in-out ${
              isMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
            }`}
          >
            <div className="py-2 space-y-1">
              {navLinks.map((link, index) => (
                <div key={index}>
                  {link.dropdown.length > 0 ? (
                    <div>
                      <button
                        onClick={(e) => handleDropdownClick(index, e)}
                        className="w-full flex items-center justify-between px-4 py-2 text-gray-700 hover:text-yellow-600 dark:hover:bg-yellow-500 hover:bg-yellow-50 transition-colors duration-200"
                      >
                        <span className="text-sm font-medium dark:text-white dark:hover:bg-yellow-500">{link.title}</span>
                        <ChevronDown
                          className={`h-4 w-4 transition-transform duration-200 ${
                            activeDropdown === index ? 'rotate-180' : ''
                          }`}
                        />
                      </button>
                      <div
                        className={`bg-gray-50 transition-all duration-200 dark:text-white dark:hover:bg-yellow-500 ${
                          activeDropdown === index ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
                        }`}
                      >
                        {link.dropdown.map((item, idx) => (
                          <Link
                            key={idx}
                            to={item.path}
                            onClick={handleLinkClick}
                            className="block px-6 py-2 text-sm text-gray-600 hover:bg-yellow-50 dark:text-white dark:hover:bg-yellow-500 hover:text-yellow-600 transition-colors duration-200"
                          >
                            {item.title}
                          </Link>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Link
                      to={link.path}
                      onClick={handleLinkClick}
                      className="block px-4 py-2 text-sm font-medium text-gray-700 hover:text-yellow-600 hover:bg-yellow-50 dark:hover:bg-yellow-500  transition-colors duration-200 dark:text-white"
                    >
                      {link.title}
                    </Link>
                  )}
                </div>
              ))}
              <div className="mt-6 pt-6">
                  <DarkModeToggle />
                </div>
                <div className='mt-6 pt-6'>
                  <LanguageSwitcher />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
    </div>
  );
};

export default NavBar;