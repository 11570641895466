import React, { useState, useEffect } from 'react';
import { MdLightMode, MdDarkMode } from 'react-icons/md';

const DarkModeToggle = () => {
  const [darkMode, setDarkMode] = useState(() => {
    return document.documentElement.classList.contains('dark');
  });

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('darkMode', 'true');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('darkMode', 'false');
    }
  }, [darkMode]);

  return (
    <div className="flex items-center space-x-2">
      <button
        onClick={() => setDarkMode(!darkMode)}
        className="relative inline-flex h-6 w-11 items-center rounded-full bg-gray-200 dark:bg-gray-700 transition-colors duration-300"
      >
        <span className="sr-only">Toggle dark mode</span>
        <span
          className={`${
            darkMode ? 'translate-x-6 bg-yellow-400' : 'translate-x-1 bg-white'
          } inline-block h-4 w-4 transform rounded-full transition-transform duration-300`}
        />
      </button>
      {darkMode ? (
        <MdDarkMode className="text-gray-800 dark:text-yellow-400" />
      ) : (
        <MdLightMode className="text-gray-800" />
      )}
    </div>
  );
};

export default DarkModeToggle;