import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { 
  HomeIcon, 
  ChevronRightIcon,
  BellAlertIcon,
  NewspaperIcon
} from '@heroicons/react/24/outline';
import { getTaarifaMatukio } from '../services/taarifaMatukioService';
import { getTaarifa } from '../services/TaarifaKwaUmmaService';
import utawala from '../assets/utawala.jpg';

const viongozi = [
  {
    title: "Mwenyekiti",
    description: "Kiongozi Mkuu wa Tume ya Uchaguzi Zanzibar",
    majukumu: [
      "Kusimamia shughuli zote za Tume",
      "Kuongoza mikutano ya Tume",
      "Kuhakikisha utekelezaji wa majukumu ya Tume"
    ]
  },
  {
    title: "Mkurugenzi wa Uchaguzi",
    description: "Msimamizi Mkuu wa Shughuli za Uchaguzi",
    majukumu: [
      "Kuratibu maandalizi ya uchaguzi",
      "Kusimamia uandikishaji wa wapiga kura",
      "Kusimamia mafunzo ya watendaji"
    ]
  },
  {
    title: "Katibu wa Tume",
    description: "Msimamizi wa Utawala na Utendaji",
    majukumu: [
      "Kusimamia shughuli za utawala",
      "Kuratibu mikutano ya Tume",
      "Kusimamia rasilimali za Tume"
    ]
  },
  {
    title: "Mkurugenzi wa Teknolojia",
    description: "Msimamizi wa Mifumo ya TEHAMA",
    majukumu: [
      "Kusimamia mifumo ya kielektroniki",
      "Kutunza kanzidata ya wapiga kura",
      "Kuhakikisha usalama wa data"
    ]
  }
];

const BreadcrumbNav = () => {
  return (
    <>
      <Helmet>
        <title>ZEC | Utawala</title>
      </Helmet>
      <nav className="bg-white shadow-sm dark:bg-gray-800">
        <div className="max-w-6xl mx-auto py-4 px-4">
          <div className="flex items-center space-x-2 text-gray-700">
            <button className="flex items-center hover:text-yellow-600 transition-all duration-300">
              <HomeIcon className="w-5 h-5 dark:text-white" />
              <span className="ml-1.5 text-sm font-medium dark:text-white">Mwanzo</span>
            </button>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <button className="flex items-center hover:text-yellow-600 transition-all duration-300">
              <span className="text-sm font-medium dark:text-white">Kuhusu Sisi</span>
            </button>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <span className="text-sm font-semibold text-yellow-600">Utawala</span>
          </div>
        </div>
      </nav>
    </>
  );
};

const NewsCard = ({ news }) => {
  return (
    <button className="w-full text-left group">
      <div className="flex gap-3 p-3 dark:bg-gray-800">
        <div className="relative w-16 h-16 flex-shrink-0 overflow-hidden rounded-lg">
          <img
            src={news.image}
            alt={news.title}
            className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
          />
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-sm text-gray-800 font-medium line-clamp-3 hover:text-yellow-600 group-hover:text-yellow-600 transition-colors duration-200 dark:text-white">
            {news.title}
          </p>
          <p className="text-sm text-gray-800 line-clamp-3 hover:text-yellow-600 group-hover:text-yellow-600 transition-colors duration-200 dark:text-white">
            {news.description}
          </p>
          <div className="text-xs text-gray-500 mt-1 dark:text-white">
            {news.date}
          </div>
        </div>
      </div>
    </button>
  );
};

const Sidebar = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const habariMpya = await getTaarifaMatukio();
        const matangazo = await getTaarifa();

        setNewsItems(habariMpya.data.slice(0, 2));
        setAnnouncements(matangazo.data.slice(0, 2));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="sticky top-20">
      <div className="bg-white rounded-lg shadow-md mb-6 dark:bg-gray-800">
        <div className="bg-yellow-500 px-4 py-3">
          <div className="flex items-center gap-2 text-white">
            <BellAlertIcon className="w-5 h-5" />
            <h2 className="text-lg font-semibold">Habari Mpya</h2>
          </div>
        </div>
        <div className="divide-y divide-gray-100 dark:text-white">
          {newsItems.map((news) => (
            <NewsCard key={news.id} news={news} />
          ))}
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md overflow-hidden dark:bg-gray-800">
        <div className="border-b border-gray-100 px-4 py-3">
          <div className="flex items-center gap-2 text-yellow-600">
            <NewspaperIcon className="w-5 h-5" />
            <h2 className="text-lg font-semibold">Matangazo</h2>
          </div>
        </div>
        <div className="p-4 space-y-3">
          {announcements.map((announcement, index) => (
            <a
              key={index}
              href={announcement.document}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-gray-700 px-2 py-1.5 rounded transition-colors group"
            >
              <ChevronRightIcon className="w-4 h-4 text-yellow-500 group-hover:text-yellow-600" />
              <span className="text-sm font-medium group-hover:text-yellow-600 dark:text-white">
                {announcement.description}
              </span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

const Utawala = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-800">
      <BreadcrumbNav />
      <div className="relative">
        <div className="max-w-6xl mx-auto px-4 py-8">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="lg:w-2/3">
              <div className="bg-white rounded-xl shadow-md p-8 dark:bg-gray-800">
                <h1 className="text-2xl font-bold text-yellow-500 border-b-2 border-yellow-200 pb-3 mb-6">
                  UTAWALA
                </h1>
                <div className="space-y-12">
                  <img src={utawala} alt="Utawala" className="rounded-lg shadow-lg" />
                </div>
              </div>
            </div>
            <div className="lg:w-1/3">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Utawala;