import React from "react";
import { useTranslation } from 'react-i18next';
import logo from "../assets/logo.png";
import logo2 from "../assets/logo2.png";


function TopBar() {

  const { t } = useTranslation();

  return (
    <div className="w-full mx-auto xl:max-w-[1920px] 2xl:max-w-[2560px] 3xl:max-w-[3840px] 4xl:max-w-[5120px] 5xl:max-w-[6400px] 6xl:max-w-[7680px]">


    <div className="bg-gradient-to-r from-gray-500 via-white to-yellow-300 text-green-700 flex items-center justify-between px-2 sm:px-2 py-0 sm:py-1 shadow-md border-b-4 border-black dark:">
      {/* Logo */}
      <div className="flex items-center lg:pl-12">
        <img
          src={logo}
          alt="SMZ logo"
          className="h-20 w-20 sm:h-20 sm:w-20 md:h-40 md:w-40 object-contain" // Responsive logo size
        />
      </div>

      {/* Name */}
      <div className="flex-1 text-center px-2 sm:px-4">
        <h5 className="hidden sm:block text-black  text-xs sm:text-xl md:text-3xl lg:text-xl pb-4">
          {t('SERIKALI YA MAPINDUZI YA ZANZIBAR')}
          
        </h5>

        <h3 className="text-black text-base font-bold sm:text-base md:text-lg lg:text-4xl mt-1 pb-2">
          {t('TUME YA UCHAGUZI YA ZANZIBAR')}
        </h3>
      </div>

      {/* Placeholder for spacing - matching logo width */}
      <div className="flex items-center lg:pr-12">
        <img
          src={logo2}
          alt="ZEC logo"
          className="h-20 w-20 sm:h-20 sm:w-20 md:h-40 md:w-40 object-contain" // Responsive logo size
        />
      </div>
    </div>
    </div>
  );
}

export default TopBar;
