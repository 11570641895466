import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import {
  HomeIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { getVideo } from "../../services/videoService";
import { useTranslation } from "react-i18next";

const MaktabaYaVideo = () => {
  const [t]= useTranslation();
  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    // Fetch videos when the component mounts
    const fetchVideos = async () => {
      try {
        const response = await getVideo();
        setVideos(response.data); // Update the state with the fetched videos
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, []);

  const videosPerPage = 15;
  const totalPages = Math.ceil(videos.length / videosPerPage);

  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

  const openModal = (video) => {
    setSelectedVideo(video);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVideo(null);
  };

  return (
    <>
      <Helmet>
        <title>ZEC | Maktaba ya Video</title>
      </Helmet>
      <nav className="bg-white shadow-sm dark:bg-gray-800">
        <div className="max-w-6xl mx-auto py-4 px-4">
          <div className="flex items-center space-x-2 text-gray-700">
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105">
              <HomeIcon className="w-5 h-5 dark:text-white" />
              <span className="ml-1.5 text-sm font-medium dark:text-white">{t('Mwanzo')}</span>
            </div>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105">
              <span className="text-sm font-medium dark:text-white">{t('Habari')}</span>
            </div>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <span className="text-sm font-semibold text-yellow-600">
              {t('Maktaba Video')}
            </span>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-center dark:text-white">
            {t('Maktaba Video')}
          </h1>
          <div className="h-1 w-20 bg-yellow-400 mx-auto"></div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {currentVideos.map((video, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
            >
              <div className="relative aspect-video">
                <img
                  src={video.image}
                  alt={video.description}
                  className="w-full h-full object-cover"
                />
                <button
                  onClick={() => openModal(video)}
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                         bg-yellow-500 hover:bg-yellow-600 text-white rounded-full w-14 h-14 
                         flex items-center justify-center transition-colors duration-300"
                >
                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </button>
              </div>
              <div className="p-4">
                <h3 className="text-sm font-medium line-clamp-2 h-12">
                  {video.description}
                </h3>
              </div>
            </div>
          ))}
        </div>

        {totalPages > 1 && (
          <div className="flex justify-center space-x-2 mt-8">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                onClick={() => setCurrentPage(i + 1)}
                className={`px-4 py-2 rounded-md transition-colors duration-300 
                       ${currentPage === i + 1
                         ? "bg-cyan-500 text-white"
                         : "bg-gray-200 hover:bg-gray-300 text-gray-700"}`}
              >
                {i + 1}
              </button>
            ))}
          </div>
        )}

        {isModalOpen && selectedVideo && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
            onClick={closeModal}
          >
            <div
              className="bg-white w-full max-w-4xl rounded-lg overflow-hidden relative"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 text-white bg-red-500 rounded-full w-8 h-8 
                       flex items-center justify-center hover:bg-red-600 transition-colors duration-300"
              >
                ×
              </button>
              <div className="aspect-video">
                <iframe
                  src={selectedVideo.link.replace("watch?v=", "embed/")}
                  title={selectedVideo.description}
                  className="w-full h-full"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className="p-4 bg-white">
                <h3 className="text-lg font-medium">{selectedVideo.description}</h3>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MaktabaYaVideo;
