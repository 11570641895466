// import React from 'react';
// import { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import spinner from "../assets/Spinner.gif";  // Adjust the path based on your file structure

// const LoadingSpinner = () => {
//   return (
//     <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
//       <div className="relative">
//         <img 
//           src={spinner} 
//           alt="Loading..." 
//           className="w-20 h-20 object-contain"  // Adjust size as needed
//         />
//       </div>
//     </div>
//   );
// };

// const PageTransition = ({ children }) => {
//   const [isLoading, setIsLoading] = useState(false);
//   const location = useLocation();

//   useEffect(() => {
//     setIsLoading(true);
//     const timer = setTimeout(() => setIsLoading(false), 800);
//     return () => clearTimeout(timer);
//   }, [location]);

//   return (
//     <>
//       {isLoading && <LoadingSpinner />}
//       {children}
//     </>
//   );
// };

// export default PageTransition;

import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import spinner from '../assets/Spinner.gif';

const LoadingSpinner = () => {
  return (
    <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
      <div className="relative">
        <img 
          src={spinner} 
          alt="Loading..." 
          className="w-32 h-32 object-contain"  // Increased from w-20 h-20 to w-32 h-32
        />
      </div>
    </div>
  );
};

const PageTransition = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 800);
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {children}
    </>
  );
};

export default PageTransition;