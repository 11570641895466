import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  HomeIcon,
  ChevronRightIcon,
  BellAlertIcon,
  ArrowDownTrayIcon,
  NewspaperIcon,
  ChevronRightIcon as ChevronRight,
} from "@heroicons/react/24/outline";
import { useTranslation } from 'react-i18next';
import { getTaarifaMatukio } from "../../services/taarifaMatukioService";
import { getTaarifa } from "../../services/TaarifaKwaUmmaService";
import { getFomu } from "../../services/FomuMaalumu";
import LatestVideos from "../../components/latestVideos";



// Improved PDF Icon Component
const DocumentPDFIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      className="text-red-500"
    >
      <path
        d="M4 4v16a2 2 0 002 2h12a2 2 0 002-2V8.342a2 2 0 00-.602-1.43l-4.44-4.342A2 2 0 0013.56 2H6a2 2 0 00-2 2z"
        fill="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <text
        x="6.5"
        y="15"
        fill="currentColor"
        fontSize="6"
        fontWeight="bold"
        fontFamily="Arial, sans-serif"
      >
        PDF
      </text>
      <path
        d="M14 2v4a2 2 0 002 2h4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
  
const FomuMaalum = () => {
  const {t} = useTranslation();
    // const [documents, setDocuments] = useState([]);
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);
  
    // useEffect(() => {
    //   const fetchDocuments = async () => {
    //     setLoading(true);
    //     try {
    //       const response = await getFomu();
    //       if (response.success) {
    //         setDocuments(response.data);
    //       } else {
    //         setError("Imeshindwa kupata taarifa");
    //       }
    //     } catch (err) {
    //       setError("Hitilafu imetokea wakati wa kupakia data");
    //       console.error(err);
    //     } finally {
    //       setLoading(false);
    //     }
    //   };
  
    //   fetchDocuments();
    // }, []);

     const [documents, setDocuments] = useState([]);
      const [newsItems, setNewsItems] = useState([]);
      const [announcements, setAnnouncements] = useState([]);
      const [loading, setLoading] = useState(true);
      const [error, setError] = useState(null);
    
      useEffect(() => {
        const fetchAllData = async () => {
          setLoading(true);
          try {
            // Fetch data from all APIs in parallel
            const [jaridaResponse, habariMpyaResponse, matangazoResponse] =
              await Promise.all([getFomu(), getTaarifaMatukio(), getTaarifa()]);
    
            // Process Jarida data
            if (jaridaResponse.success) {
              setDocuments(jaridaResponse.data);
            } else {
              setError("Imeshindwa kupata Fomu");
            }
    
            // Process Habari Mpya data
            if (habariMpyaResponse.success) {
              setNewsItems(habariMpyaResponse.data.slice(0, 2));
            }
    
            // Process Matangazo data
            if (matangazoResponse.success) {
              setAnnouncements(matangazoResponse.data.slice(0, 2));
            }
          } catch (err) {
            setError("Imeshindwa kupakia data");
            console.error("Error fetching data:", err);
          } finally {
            setLoading(false);
          }
        };
    
        fetchAllData();
      }, []);
    
      if (loading) {
        return <div>Loading...</div>;
      }
    
      if (error) {
        return <div>{error}</div>;
      }
  
    return (
      <>
        <Helmet>
          <title>Machapisho | Fomu Maalumu</title>
        </Helmet>
  
        {/* Navigation */}
        <nav className="bg-white shadow-sm dark:bg-gray-800">
          <div className="max-w-6xl mx-auto py-4 px-4">
            <div className="flex items-center space-x-2 text-gray-700">
              <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-transform duration-300">
                <HomeIcon className="w-5 h-5 dark:text-white" />
                <span className="ml-1.5 text-sm font-medium dark:text-white">{t('Mwanzo')}</span>
              </div>
              <ChevronRightIcon className="w-4 h-4 dark:text-white" />
              <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105">
              <span className="text-sm font-medium dark:text-white">
                {t('Machapisho')}
              </span>
            </div>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
              <span className="text-sm font-semibold text-yellow-600">
                {t('Fomu Maalumu')}
              </span>
            </div>
          </div>
        </nav>
  
        {/* Main Content */}
        <div className="min-h-screen bg-gray-50 dark:bg-gray-800">
          <div className="max-w-6xl mx-auto p-6 py-8 grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Jarida Section */}
            <div className="lg:col-span-2 bg-white rounded-xl shadow-md p-8 hover:shadow-lg transition-transform duration-300 dark:bg-gray-800">
              <h1 className="text-2xl font-bold text-yellow-500 border-b-2 border-yellow-200 pb-3 mb-6">
                {t('Fomu Maalumu')}
              </h1>
  
              <div className="space-y-4">
                            {loading ? (
                              <div className="text-center py-4 dark:text-white">
                                Inapakia jarida...
                              </div>
                            ) : error ? (
                              <div className="text-center text-red-500 py-4">{error}</div>
                            ) : documents.length === 0 ? (
                              <div className="text-center py-4 dark:text-white">
                                Hakuna Taarifa kwa sasa
                              </div>
                            ) : (
                              documents.map((doc) => (
                                <div
                                  key={doc.id}
                                  className="flex items-center p-4 border rounded-md hover:shadow-lg transition-shadow duration-300 group dark:hover:text-yellow-500"
                                >
                                  <div className="w-10 h-10 flex-shrink-0 flex items-center justify-center rounded bg-gray-50">
                                    <DocumentPDFIcon />
                                  </div>
              
                                  <a
                                    href={doc.document}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                    className="flex flex-1 items-center ml-4 group-hover:text-yellow-600 transition-colors duration-300 dark:text-white"
                                  >
                                    <div className="flex-1">
                                      <p className="text-sm text-gray-800 font-medium group-hover:text-yellow-600 dark:group-hover:text-yellow-600 dark:text-white">
                                        {doc.title}
                                      </p>
                                      <p className="text-xs text-gray-500 hover:text-yellow-600  dark:hover:text-yellow-600  mt-1 dark:text-white">
                                        {doc.description}
                                      </p>
                                      <div className="text-xs text-gray-500 mt-1 hover:text-yellow-600 dark:text-white  dark:hover:text-yellow-600 ">
                                        {doc.date}
                                      </div>
                                    </div>
                                    <ArrowDownTrayIcon className="w-5 h-5 text-yellow-600 transition-colors duration-300" />
                                  </a>
                                </div>
                              ))
                            )}
                          </div>
            </div>

              {/* Right Side News and Ads Section */}
          <div className="space-y-6">
            {/* News Section */}
            <div className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-transform duration-300 dark:bg-gray-800">
              <div className="bg-yellow-500 px-4 py-3">
                <div className="flex items-center gap-2 text-white">
                  <BellAlertIcon className="w-5 h-5" />
                  <h2 className="text-lg font-semibold">{t('Habari Mpya')}</h2>
                </div>
              </div>

              <div className="divide-y divide-gray-100">
                {newsItems.map((news) => (
                  <a
                    key={news.id}
                    href="#"
                    className="block hover:bg-gray-50 transition-colors group dark:hover:bg-yellow-300"
                  >
                    <div className="flex gap-3 p-3">
                      <div className="relative w-16 h-16 flex-shrink-0 overflow-hidden rounded">
                        <img
                          src={news.image}
                          alt={news.title}
                          className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300 dark:text-white"
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm text-gray-800 font-medium line-clamp-3 group-hover:text-yellow-600 transition-colors dark:text-white">
                          {news.title}
                        </p>
                        <p className="text-gray-800 dark:text-white">
                          {news.description}
                        </p>
                        <div className="text-xs text-gray-500 mt-1 dark:text-white">
                          {news.date}
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>

            <LatestVideos />
            {/* Ads Section */}
            <div className="bg-white rounded-lg shadow-sm overflow-hidden dark:bg-gray-800 mt-6">
              {/* Reduced padding from px-4 to px-2 */}
              <div className="border-b border-gray-100 px-2 py-3">
                <div className="flex items-center gap-2 text-yellow-600">
                  <NewspaperIcon className="w-5 h-5" />
                  <h2 className="text-lg font-semibold">{t('Matangazo')}</h2>
                </div>
              </div>
              {/* Reduced padding from p-4 to p-2 */}
              <div className="p-2 space-y-3">
                {announcements.map((announcement, index) => (
                  <a
                    key={index}
                    href={announcement.document}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 text-gray-700 hover:text-yellow-600 transition-all duration-300 group transform hover:translate-x-2"
                  >
                    <ChevronRight className="w-4 h-4 text-yellow-500 group-hover:text-yellow-600" />
                    <span className="text-sm font-medium dark:text-white">
                      {announcement.description}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FomuMaalum;