import React, { useState, useEffect } from 'react';
import { getFAQs } from '../services/FaqService'; // Ensure correct path
import { useTranslation } from 'react-i18next';

const FAQPage = () => {
  const {t} = useTranslation();
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [openItem, setOpenItem] = useState(null);
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await getFAQs();
        if (response.success) {
          setFaqs(response.data);
        }
      } catch (error) {
        setError("Failed to fetch FAQs.");
      } finally {
        setLoading(false);
      }
    };

    fetchFAQs();
  }, []);

  // Calculate pagination
  const totalPages = Math.ceil(faqs.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentFaqs = faqs.slice(startIndex, endIndex);

  const toggleAccordion = (id) => {
    setOpenItem(openItem === id ? null : id);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8 dark:text-white">{t('Maswali Yanayoulizwa Mara Kwa Mara')}</h1>

      {loading ? (
        <p className="text-center dark:text-white">Loading FAQs...</p>
      ) : error ? (
        <p className="text-center text-red-500">{error}</p>
      ) : (
        <div className="flex flex-col md:flex-row gap-8">
          {/* Main content - FAQ Accordion (3/4 width) */}
          <div className="md:w-3/4">
            <div className="space-y-4">
              {currentFaqs.map((faq) => (
                <div key={faq.id} className="border rounded-lg overflow-hidden">
                  <button
                    onClick={() => toggleAccordion(faq.id)}
                    className="w-full flex justify-between items-center p-4 bg-white hover:bg-gray-50 transition-colors dark:bg-gray-800 dark:text-white"
                  >
                    <span className="text-lg font-medium dark:text-white">{faq.question}</span>
                    <span className={`dark:text-dark transform transition-transform duration-200 ${openItem === faq.id ? 'rotate-180' : ''}`}>
                      ▼
                    </span>
                  </button>
                  <div
                    className={`overflow-hidden transition-all duration-200 ease-in-out ${
                      openItem === faq.id ? 'max-h-96' : 'max-h-0'
                    }`}
                  >
                    <div className="p-4 bg-gray-50 dark:bg-gray-800 text-gray-600 dark:text-white">{faq.answer}</div>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination */}
            {totalPages > 1 && (
              <div className="flex justify-center gap-2 mt-8">
                <button
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className="px-4 py-2 border rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 dark:text-white dark:hover:text-gray-950"
                >
                  Previous
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i + 1}
                    onClick={() => setCurrentPage(i + 1)}
                    className={`px-4 py-2 border rounded-md hover:bg-gray-50 ${
                      currentPage === i + 1 ? 'bg-yellow-500 text-white hover:bg-gray-600 dark:text-white' : ''
                    }`}
                  >
                    {i + 1}
                  </button>
                ))}
                <button
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className="px-4 py-2 border rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 dark:text-white dark:hover:text-gray-950"
                >
                  Next
                </button>
              </div>
            )}
          </div>

          {/* Aside content (1/4 width) */}
          <aside className="md:w-1/4 bg-gray-50 p-6 rounded-lg h-fit dark:bg-gray-800">
            <h2 className="text-xl font-semibold mb-4 dark:text-white">Je, unahitaji Usaidizi Zaidi?</h2>
            <p className="text-gray-600 mb-4 dark:text-white">
              Je, huwezi kupata unachotafuta? Timu yetu ya usaidizi iko hapa kusaidia!
            </p>
            <div className="space-y-4">
              <button className="w-full bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors">
                <a href="/contact">Wasiliana na Usaidizi</a>
              </button>
              <div className="text-sm text-gray-500 dark:text-white">
                <p className="mb-2 dark:text-white">Saa Zinazopatikana:</p>
                <p className="dark:text-white">Jumatatu - Ijumaa</p>
                <p className="dark:text-white">9:00 AM - 5:00 PM EST</p>
              </div>
            </div>
          </aside>
        </div>
      )}
    </div>
  );
};

export default FAQPage;
