import React from 'react';
import { Helmet } from 'react-helmet-async';
import SplitLayout from '../pages-components/home/SplitLayout';
import IntroductionSection from '../pages-components/home/IntroductionSection';
import StatisticsSection from '../pages-components/home/StatisticsSection';
import Event from '../pages-components/home/Events';
import Videos from '../pages-components/home/Videos';
import CountdownPage from '../pages-components/home/Days';
import NewsAndEventsPage from '../pages-components/home/news';
const Home = () => {
    return (
        <>
        <Helmet>
               <title>ZEC | Mwanzo</title>
               <meta name="description" content="Tume ya Uchaguzi Zanzibar - Tovuti Rasmi ya ZEC. Taarifa rasmi, matukio, na habari kuhusu uchaguzi Zanzibar." />
           </Helmet>
        <div className='bg-white dark:bg-gray-800'>
            <SplitLayout />
            <IntroductionSection />
            <NewsAndEventsPage />
            {/* <Event /> */}
            <StatisticsSection />
            <Videos />
            <CountdownPage />
        </div>
        </>
    );
};
export default Home;