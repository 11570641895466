
import axios from './axios';

export const sendMessage  = async (data) => {
    try {
        const response = await axios.post('/contact', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};