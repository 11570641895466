import React, { useState, useEffect } from 'react';
import { AlertCircleIcon } from 'lucide-react';
import { getVideo } from '../services/videoService';
import { useTranslation } from 'react-i18next';

const LatestVideos = () => {
  const {t} = useTranslation();
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await getVideo();
        // Take only the two most recent videos
        const latestVideos = response.data.slice(0, 2);
        setVideos(latestVideos);
      } catch (err) {
        console.error('Error fetching videos:', err);
        setError(err);
      }
    };

    fetchVideos();
  }, []);

  if (error) {
    return <div className="text-red-500">Error loading videos</div>;
  }

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-transform duration-300 dark:bg-gray-800 mt-6">
      <div className="bg-yellow-500 px-4 py-3">
        <div className="flex items-center gap-2 text-white">
          <AlertCircleIcon className="w-5 h-5" />
          <h2 className="text-lg font-semibold">{t('Habari Mpya (Videos)')}</h2>
        </div>
      </div>

      <div className="divide-y divide-gray-100">
        {videos.map((video) => (
          <a
            key={video.id}
            href={video.link}
            target="_blank"
            rel="noopener noreferrer"
            className="block hover:bg-gray-50 transition-colors group dark:hover:bg-yellow-300"
          >
            <div className="flex gap-3 p-3">
              <div className="relative w-16 h-16 flex-shrink-0 overflow-hidden rounded">
                <img
                  src={video.image}
                  alt={video.description}
                  className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300 dark:text-white"
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm text-gray-800 font-medium line-clamp-3 group-hover:text-yellow-600 transition-colors dark:text-white">
                  {video.description}
                </p>
                <div className="text-xs text-gray-500 mt-1 dark:text-white">
                  {video.date}
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default LatestVideos;