import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  HomeIcon,
  ChevronRightIcon,
  BellAlertIcon,
  ArrowDownTrayIcon,
  NewspaperIcon,
  ChevronRightIcon as ChevronRight,
} from "@heroicons/react/24/outline";
import { useTranslation } from 'react-i18next';
import { getTaarifaMatukio } from "../../services/taarifaMatukioService";
// import { getTaarifa as getTaarifaKwaUmma } from "../../services/TaarifaKwaUmmaService";
import { getTaarifa as getTaarifaUmma } from "../../services/TaarifaKwaUmmaService";
import { getTaarifa } from "../../services/taarifaService";
import LatestVideos from "../../components/latestVideos";
import habari1 from "../../assets/img01.jpg";
import habari2 from "../../assets/img02.jpg";


// Adobe-style PDF Icon Component with centered text
const DocumentPDFIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className="text-red-600"
  >
    <path d="M3 3v18h18V7l-4-4H3z" fill="currentColor" />
    <path d="M17 3v4h4" fill="none" stroke="white" strokeWidth="1" />
    <text
      x="12"
      y="15"
      fill="white"
      fontSize="6"
      fontFamily="Arial, sans-serif"
      textAnchor="middle"
      fontWeight="bold"
    >
      PDF
    </text>
  </svg>
);

// const newsItems = [
//   {
//     id: 1,
//     title:
//       "Mwenyekiti wa ZEC awataka watendaji wa vituo vya kupigia kura kufanya kazi kwa weledi",
//     date: "24 Dec, 2024",
//     image: habari1,
//   },
//   {
//     id: 2,
//     title:
//       "Mwenyekiti wa Tume ya Uchaguzi Zanzibar, azindua mafunzo kwa watendaji wa vituo",
//     date: "09 Dec, 2024",
//     image: habari2,
//   },
// ];

const TaarifazaUchaguzi = () => {
  const {t} = useTranslation();
  const [documents, setDocuments] = useState([]);
const [newsItems, setNewsItems] = useState([]);
const [announcements, setAnnouncements] = useState([]);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);

useEffect(() => {
  const fetchAllData = async () => {
    try {
      // Fetch all data concurrently
      const [documentsResponse, newsItemsResponse, announcementsResponse] = await Promise.all([
        getTaarifa(),
        getTaarifaMatukio(),
        getTaarifaUmma(),
      ]);

      // Process documents from `getTaarifa`
      if (documentsResponse.success) {
        const formattedDocuments = documentsResponse.data.map((item) => ({
          id: item.id,
          title: item.description,
          date: new Date(item.date).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }),
          type: "pdf",
          documentUrl: item.document,
        }));
        setDocuments(formattedDocuments);
      } else {
        console.warn("Documents data fetch failed:", documentsResponse);
      }

      // Process news items from `getTaarifaMatukio`
      if (newsItemsResponse.success) {
        setNewsItems(newsItemsResponse.data.slice(0, 2));
      } else {
        console.warn("News items data fetch failed:", newsItemsResponse);
      }

      // Process announcements from `getTaarifaUmma`
      if (announcementsResponse.success) {
        setAnnouncements(announcementsResponse.data.slice(0, 2));
      } else {
        console.warn("Announcements data fetch failed:", announcementsResponse);
      }
    } catch (err) {
      setError("Imeshindwa kupata taarifa");
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  fetchAllData();
}, []);

// Render logic here (e.g., loading spinner, error message, or data)
if (loading) return <p>Loading...</p>;
if (error) return <p>{error}</p>;

  return (
    <>
      <Helmet>
        <title>ZEC | Taarifa za Uchaguzi</title>
      </Helmet>
      <nav className="bg-white shadow-sm dark:bg-gray-800">
        <div className="max-w-6xl mx-auto py-4 px-4">
          <div className="flex items-center space-x-2 text-gray-700">
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105">
              <HomeIcon className="w-5 h-5 dark:text-white" />
              <span className="ml-1.5 text-sm font-medium dark:text-white">
                {t('Mwanzo')}
              </span>
            </div>

            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105">
              <span className="text-sm font-medium dark:text-white">
                {t('Uchaguzi')}
              </span>
            </div>

            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <span className="text-sm font-semibold text-yellow-600">
              {t('Taarifa za Uchaguzi')}
            </span>
          </div>
        </div>
      </nav>

      {/* Content Section */}
      <div className="min-h-screen bg-gray-50 dark:bg-gray-800">
        <div className="max-w-6xl mx-auto p-6 py-8 grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Main Content */}
          <div className="lg:col-span-2 bg-white rounded-xl shadow-md p-8 hover:shadow-lg transition-transform duration-300 dark:bg-gray-800">
            <h1 className="text-2xl font-bold text-yellow-500 border-b-2 border-yellow-200 pb-3 mb-6">
              {t('TAARIFA ZA UCHAGUZI')}
            </h1>

            {/* Document List */}
            <div className="space-y-4">
              {loading ? (
                <div className="text-center py-4 dark:text-white">
                  Inapakia taarifa...
                </div>
              ) : error ? (
                <div className="text-center text-red-500 py-4">{error}</div>
              ) : documents.length === 0 ? (
                <div className="text-center py-4 dark:text-white">
                  Hakuna taarifa kwa sasa
                </div>
              ) : (
                documents.map((doc) => (
                  <div
                    key={doc.id}
                    className="flex items-center p-4 border rounded-md hover:shadow-lg transition-shadow duration-300 group dark:text-white"
                  >
                    {/* Icon */}
                    <div className="w-10 h-10 flex-shrink-0 flex items-center justify-center rounded bg-gray-50 dark:text-white">
                      <DocumentPDFIcon />
                    </div>

                    {/* Content */}
                    <div className="flex-1 ml-4">
                      <p className="text-sm text-gray-800 font-medium group-hover:text-yellow-600 dark:text-white">
                        {doc.title}
                      </p>
                      <div className="text-xs text-gray-500 mt-1 dark:text-white">
                        {doc.date}
                      </div>
                    </div>

                    {/* Download Link */}
                    <a
                      href={doc.documentUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="text-yellow-600 font-medium text-sm flex items-center gap-1 hover:text-yellow-700 transition-colors duration-300 dark:text-white"
                    >
                      <ArrowDownTrayIcon className="w-5 h-5" />
                      Pakua
                    </a>
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Sidebar */}
          <div className="space-y-6">
            {/* Latest News */}
            <div className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-transform duration-300 dark:bg-gray-800">
              <div className="bg-yellow-500 px-4 py-3">
                <div className="flex items-center gap-2 text-white">
                  <BellAlertIcon className="w-5 h-5" />
                  <h2 className="text-lg font-semibold">{t('Habari Mpya')}</h2>
                </div>
              </div>

              <div className="divide-y divide-gray-100 dark:text-white">
                {newsItems.map((news) => (
                  <a
                    key={news.id}
                    href="#"
                    className="block hover:bg-gray-50 transition-colors group"
                  >
                    <div className="flex gap-3 p-3">
                      <div className="relative w-16 h-16 flex-shrink-0 overflow-hidden rounded">
                        <img
                          src={news.image}
                          alt={news.title}
                          className="absolute inset-0 w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300 dark:text-white"
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm text-gray-800 font-medium line-clamp-3 group-hover:text-yellow-600 transition-colors dark:text-white">
                          {news.title}
                        </p>
                        <p className="text-gray-800 dark:text-white">
                          {news.description}
                        </p>
                        <div className="text-xs text-gray-500 mt-1 dark:text-white">
                          {news.date}
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
            <LatestVideos />
            {/* Announcements */}
            <div className="bg-white rounded-lg shadow-sm overflow-hidden dark:bg-gray-800 mt-6">
              {/* Reduced padding from px-4 to px-2 */}
              <div className="border-b border-gray-100 px-2 py-3">
                <div className="flex items-center gap-2 text-yellow-600">
                  <NewspaperIcon className="w-5 h-5" />
                  <h2 className="text-lg font-semibold">{t('Matangazo')}</h2>
                </div>
              </div>
              {/* Reduced padding from p-4 to p-2 */}
              <div className="p-2 space-y-3">
                {announcements.map((announcement, index) => (
                  <a
                    key={index}
                    href={announcement.document}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 text-gray-700 hover:text-yellow-600 transition-colors group"
                  >
                    <ChevronRight className="w-4 h-4 text-yellow-500 group-hover:text-yellow-600" />
                    <span className="text-sm font-medium dark:text-white">
                      {announcement.description}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaarifazaUchaguzi;
