import React, { useState, useEffect } from 'react';
import bgImage from '../../assets/watu.jpg';
import { getMatukio } from '../../services/matukioService';
import logo2 from "../../assets/logo2.png";
import { useTranslation } from 'react-i18next';

const CountdownLayout = () => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const [eventTitle, setEventTitle] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let timer = null;
    
    const fetchEventAndStartTimer = async () => {
      try {
        const response = await getMatukio();
        
        if (!response.data || response.data.length === 0) {
          console.error("No events found.");
          setEventTitle("No Upcoming Events");
          setLoading(false);
          return;
        }
    
        const event = response.data[0]; 
        setEventTitle(event.title);
    
        // Function to calculate time difference with Tanzania timezone
        const calculateTimeLeft = () => {
          const targetDate = new Date(event.days_remaining);
          targetDate.setHours(23, 59, 59); 
    
          const now = new Date();
          const tanzaniaOffset = 3;
          const utc = now.getTime() + now.getTimezoneOffset() * 60000;
          const tanzaniaTime = new Date(utc + 3600000 * tanzaniaOffset);
    
          const difference = targetDate - tanzaniaTime;
    
          if (difference <= 0) {
            if (timer) clearInterval(timer);
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
          }
    
          return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((difference % (1000 * 60)) / 1000),
          };
        };
    
        setTimeLeft(calculateTimeLeft());
        setLoading(false);
    
        timer = setInterval(() => {
          const newTimeLeft = calculateTimeLeft();
          setTimeLeft(newTimeLeft);
    
          if (Object.values(newTimeLeft).every((val) => val === 0)) {
            clearInterval(timer);
          }
        }, 1000);
    
      } catch (error) {
        console.error("Error fetching event:", error);
        setEventTitle("Failed to load events");
        setLoading(false);
      }
    };
    

    fetchEventAndStartTimer();

    return () => {
      if (timer) clearInterval(timer);
    };
  }, []); // Empty dependency array

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[40vh]">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-yellow-500"></div>
      </div>
    );
  }

  return (
    <div className="w-full mx-auto xl:max-w-[1920px] 2xl:max-w-[2560px] 3xl:max-w-[3840px] 4xl:max-w-[5120px] 5xl:max-w-[6400px] 6xl:max-w-[7680px]">
    <div
      className="w-full h-[60vh] lg:h-[40vh] relative bg-cover bg-center"
      style={{
        height: '250px', // Default height
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.6)), url(${bgImage})`,
      }}
    >
      {/* Header Text */}
      <div className="w-full bg-gradient-to-r from-gray-500 via-white to-yellow-300 p-4 text-center dark:bg-gray-800">
      <h1 className="text-xl md:text-2xl font-bold text-gray-900 dark:text-gray-950 px-4">
          {eventTitle}
        </h1>
      </div>

      {/* Statistics Container */}
      <div className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-4 gap-8 text-center">
          {/* Days */}
          <div className="flex flex-col items-center">
            <div className="text-6xl font-bold mb-2 text-black">
              {timeLeft.days.toString().padStart(2, '0')}
            </div>
            <div className="text-xl uppercase text-black">{t('Siku')}</div>
          </div>

          {/* Hours */}
          <div className="flex flex-col items-center">
            <div className="text-6xl font-bold mb-2 text-black">
              {timeLeft.hours.toString().padStart(2, '0')}
            </div>
            <div className="text-xl uppercase text-black">{t('Saa')}</div>
          </div>

          {/* Minutes */}
          <div className="flex flex-col items-center">
            <div className="text-6xl font-bold mb-2 text-black">
              {timeLeft.minutes.toString().padStart(2, '0')}
            </div>
            <div className="text-xl uppercase text-black">{t('Dakika')}</div>
          </div>

          {/* Seconds */}
          <div className="flex flex-col items-center">
            <div className="text-6xl font-bold mb-2 text-black">
              {timeLeft.seconds.toString().padStart(2, '0')}
            </div>
            <div className="text-xl uppercase text-black">{t('Sekunde')}</div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CountdownLayout;