import React, { useState } from "react";
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { Helmet } from 'react-helmet-async';
import {
  HomeIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { sendMessage } from '../services/contactService'; // Import sendMessage function
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const {t} = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status
  const [responseMessage, setResponseMessage] = useState(null); // For displaying success or error messages

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start the submission process

    try {
      const response = await sendMessage(formData); // Send form data using the sendMessage function
      setResponseMessage(response.message); // Show success message from the API
      // Clear the form fields after successful submission
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting contact form:", error);
      setResponseMessage("There was an error submitting your message. Please try again.");
    } finally {
      setIsSubmitting(false); // Stop the submission process
    }
  };

  return (
    <>
      <Helmet>
        <title>ZEC | Wasiliana Nasi</title>
      </Helmet>

      <nav className="bg-white shadow-sm dark:bg-gray-800">
        <div className="max-w-6xl mx-auto py-4 px-4">
          <div className="flex items-center space-x-2 text-gray-700">
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105">
              <HomeIcon className="w-5 h-5 dark:text-white" />
              <span className="ml-1.5 text-sm font-medium dark:text-white">Mwanzo</span>
            </div>

            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <span className="text-sm font-semibold text-yellow-600">
              {t('Wasiliana Nasi')}
            </span>
          </div>
        </div>
      </nav>

      <div className="min-h-screen bg-white flex justify-center items-center py-12 px-4 sm:px-6 lg:px-8 dark:bg-gray-800">
        <div className="w-full max-w-7xl grid grid-cols-1 md:grid-cols-2 gap-8 bg-white p-8 rounded-lg shadow-lg dark:bg-gray-800">
          {/* Left Side: Address, Email, Call Us */}
          <div className="space-y-8 dark:bg-gray-800">
            <h1 className="text-xl font-bold text-yellow-600 mb-6 border-b pb-2 dark:text-white">
              {t('WASILIANA NASI')}
            </h1>
            <div className="flex items-center space-x-4">
              <FaMapMarkerAlt className="text-yellow-500 text-2xl" />
              <div>
                <h3 className="text-lg font-medium text-gray-700 dark:text-white">
                  {t('Anwani yetu')}
                </h3>
                <p className="text-gray-600 dark:text-white">
                  1 Barabara ya Dr. Hussein Mwinyi, Maisara,
                  <br />
                  {t('S.L.P')} 1001. 71125 Mjini Magharibi,
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <FaEnvelope className="text-yellow-500 text-2xl" />
              <div>
                <h3 className="text-lg font-medium text-gray-700 dark:text-white">
                  {t('Tutumie Barua pepe')}
                </h3>
                <p className="text-gray-600 dark:text-white">info@zec.go.tz</p>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <FaPhoneAlt className="text-yellow-500 text-2xl" />
              <div>
                <h3 className="text-lg font-medium text-gray-700 dark:text-white">{t('Piga Simu')}</h3>
                <p className="text-gray-600 dark:text-white">{t('Simu')}: +255 242 231 489</p>
              </div>
            </div>
          </div>

          {/* Right Side: Message Form */}
          <div className="bg-white p-8 rounded-lg shadow-lg dark:bg-gray-800">
            <h2 className="text-3xl font-extrabold text-center text-gray-900 dark:text-white">
              {t('Tufikie')}
            </h2>
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    {t('Jina Kamili')}
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                  />
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    {t('Barua pepe')}
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                  />
                </div>

                <div>
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    {t('Ujumbe')}
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    rows="4"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                  />
                </div>
              </div>

              {responseMessage && (
                <div className="text-center  text-sm mt-2 text-yellow-600">
                  {responseMessage}
                </div>
              )}

              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  disabled={isSubmitting} // Disable the button while submitting
                  className="w-full py-2 px-4 border border-transparent rounded-md shadow-lg text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 flex justify-center items-center"
                >
                  {isSubmitting ? (
                    <div className="w-6 h-6 border-4 border-t-4 border-yellow-500 rounded-full animate-spin"></div>
                  ) : (
                    t("Tuma Ujumbe")
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Google Map Embed Below the Form */}
      <div className="w-full h-96 mt-12">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.432123456789!2d39.20234567890123!3d-6.165432109876543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c1234567890ab%3A0xabcdef1234567890!2sTume%20ya%20Uchaguzi%20ya%20Zanzibar!5e0!3m2!1ssw!2stz!4v1735649242070!5m2!1ssw!2stz"
          width="100%"
          height="100%"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default Contact;
