// import React, { useEffect } from 'react';
// import { useTranslation } from 'react-i18next';

// const LanguageSwitcher = () => {
//   const { t, i18n } = useTranslation();
//   // const [isChecked, setIsChecked] = React.useState(i18n.language === 'en');
//   const [isChecked, setIsChecked] = React.useState(false);

//   useEffect(() => {
//     setIsChecked(i18n.language === 'en');
//   }, [i18n.language]);

//   // const toggleLanguage = () => {
//   //   const newLang = i18n.language === 'sw' ? 'en' : 'sw';
//   //   i18n.changeLanguage(newLang);
//   //   setIsChecked(!isChecked);
//   // };

//   const toggleLanguage = () => {
//     const newLang = i18n.language === 'sw' ? 'en' : 'sw';
//     i18n.changeLanguage(newLang);
//     setIsChecked(newLang === 'en');
//   };

//   return (
//     <div className="flex items-center space-x-3">
//       {/* Tanzania Flag */}
//       <div className={`flex items-center space-x-2 transition-opacity duration-200 ${isChecked ? 'opacity-50' : 'opacity-100'}`}>
//       {/* <svg className="h-4 w-6" viewBox="0 0 640 480">
//           <defs>
//             <clipPath id="a">
//               <path fillOpacity=".7" d="M-85.3 0h682.6v512H-85.3z"/>
//             </clipPath>
//           </defs>
//           <g clipPath="url(#a)" transform="translate(80) scale(.9375)">
//             <g fillRule="evenodd" strokeWidth="1pt">
//               <path fill="#09f" d="M-128 0h768v512h-768z"/>
//               <path fill="#090" d="M-128 0h768L-128 512V0z"/>
//               <path fill="#ff0" d="M-128 512h768L-128 0v512z"/>
//               <path d="M-128 512h768L-128 0v512zM640 0h-768L640 512V0z" transform="translate(0 -170.7)"/>
//             </g>
//           </g>
//         </svg> */}
//         <span className="text-sm">Kiswahili</span>
//       </div>

//       {/* Custom Toggle Switch */}
//       <label className="relative inline-flex items-center cursor-pointer">
//         <input 
//           type="checkbox" 
//           className="sr-only peer"
//           checked={isChecked}
//           onChange={toggleLanguage}
//         />
//         <div className="w-11 h-6 bg-gray-300 dark:bg-gray-600 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600">
//         </div>
//       </label>

//       {/* UK Flag */}
//       <div className={`flex items-center space-x-2 transition-opacity duration-200 ${isChecked ? 'opacity-100' : 'opacity-50'}`}>
//         <svg className="h-4 w-6" viewBox="0 0 640 480">
//           <path fill="#012169" d="M0 0h640v480H0z"/>
//           <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
//           <path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
//           <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
//           <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
//         </svg>
//         <span className="text-sm">English</span>
//       </div>
//     </div>
//   );
// };

// export default LanguageSwitcher;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const [isChecked, setIsChecked] = React.useState(i18n.language === 'en');

  useEffect(() => {
    setIsChecked(i18n.language === 'en');
  }, [i18n.language]);

  const toggleLanguage = () => {
    const newLang = i18n.language === 'sw' ? 'en' : 'sw';
    i18n.changeLanguage(newLang).then(() => {
      setIsChecked(newLang === 'en');
    });
  };

  console.log('Current language:', i18n.language); // Debugging statement
  console.log('Translations:', t('Nyumbani')); // Debugging statement

  return (
    <div className="flex items-center space-x-3">
      <div className={`flex items-center space-x-2 transition-opacity duration-200 ${isChecked ? 'opacity-50' : 'opacity-100'}`}>
        <span className="text-sm">Kiswahili</span>
      </div>

      <label className="relative inline-flex items-center cursor-pointer">
        <input 
          type="checkbox" 
          className="sr-only peer"
          checked={isChecked}
          onChange={toggleLanguage}
        />
        <div className="w-11 h-6 bg-gray-300 dark:bg-gray-600 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-600">
        </div>
      </label>

      <div className={`flex items-center space-x-2 transition-opacity duration-200 ${isChecked ? 'opacity-100' : 'opacity-50'}`}>
        <svg className="h-4 w-6" viewBox="0 0 640 480">
          <path fill="#012169" d="M0 0h640v480H0z"/>
          <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
          <path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
          <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
          <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
        </svg>
        <span className="text-sm">English</span>
      </div>
    </div>
  );
};

export default LanguageSwitcher;