import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import {
  HomeIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from 'react-i18next';
// Import the function to fetch data
import { getTaarifaMatukio } from '../../services/taarifaMatukioService';

const PhotoGallery = () => {
  const [t]= useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [touchedId, setTouchedId] = useState(null);
  const [photos, setPhotos] = useState([]); // State for photos

  useEffect(() => {
    // Fetch data from the API when the component mounts
    const fetchPhotos = async () => {
      try {
        const response = await getTaarifaMatukio();
        // Check if response data is available and it's an array
        if (response && response.data && Array.isArray(response.data)) {
          setPhotos(response.data); // Update state with the correct array
        } else {
          console.error("Invalid response structure");
          setPhotos([]); // Set empty array if the structure is not as expected
        }
      } catch (error) {
        console.error("Error fetching photos:", error);
        setPhotos([]); // Set an empty array in case of an error
      }
    };

    fetchPhotos();
  }, []); // Empty dependency array means this runs only once on mount

  const handleTouchStart = (id) => {
    setTouchedId(id);
  };

  const handleTouchEnd = () => {
    setTouchedId(null);
  };

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'short', year: '2-digit' };
    return new Intl.DateTimeFormat('en-GB', options).format(date).toUpperCase();
  };

  return (
    <>
      <Helmet>
        <title>ZEC | Maktaba ya Picha</title>
      </Helmet>
      {/* Navbar */}
      <nav className="bg-white shadow-sm dark:bg-gray-800">
        <div className="max-w-6xl mx-auto py-4 px-4">
          <div className="flex items-center space-x-2 text-gray-700">
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105">
              <HomeIcon className="w-5 h-5 dark:text-white" />
              <span className="ml-1.5 text-sm font-medium dark:text-white">{t('Mwanzo')}</span>
            </div>
            <ChevronRightIcon className="w-4 h-4 dark:text-white" />
            <div className="flex items-center hover:text-yellow-600 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105">
              <span className="text-sm font-medium dark:text-white">{t('Habari')}</span>
            </div>
            <ChevronRightIcon className="w-4 h-4" />
            <span className="text-sm font-semibold text-yellow-600">
              {t('Maktaba Ya Picha')}
            </span>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-center dark:text-white">{t('Maktaba Picha')}</h1>
          <div className="h-1 w-16 bg-yellow-400 mx-auto"></div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {photos.length > 0 ? (
            photos.map((photo) => (
              <div
                key={photo.id}
                className={`bg-white rounded-lg shadow-md overflow-hidden 
                     transform transition-all duration-200 active:scale-95 dark:bg-gray-800
                     ${touchedId === photo.id ? "scale-95 shadow-xl" : "hover:scale-102 hover:shadow-lg"}`}
                onTouchStart={() => handleTouchStart(photo.id)}
                onTouchEnd={handleTouchEnd}
                onClick={() => setSelectedImage(photo)}
              >
                <div className="relative group">
                  <img
                    src={photo.image}
                    alt={photo.title}
                    className="w-full h-48 object-cover"
                  />
                  <div
                    className="absolute inset-0 bg-black opacity-0 group-hover:opacity-20 
                             transition-opacity duration-200 pointer-events-none"
                  ></div>
                  <div
                    className="absolute top-2 right-2 bg-orange-500 text-white rounded-full 
                             px-3 py-1 text-sm transform transition-transform duration-200 
                             group-hover:scale-105"
                  >
                    {formatDate(photo.date)}
                  </div>
                </div>
                <div className="p-4 group-hover:bg-gray-50 transition-colors duration-200 dark:bg-gray-800">
                  <h3 className="text-sm font-medium line-clamp-2 h-10 dark:text-white">
                    {photo.title}
                  </h3>
                </div>
              </div>
            ))
          ) : (
            <p className="dark:text-white">Hakuna Taarifa kwa sasa.......</p>
          )}
        </div>

        {/* Modal */}
        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
            onClick={() => setSelectedImage(null)}
          >
            <div
              className="relative max-w-4xl w-full transform transition-transform duration-300 scale-100"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={() => setSelectedImage(null)}
                className="absolute -top-10 right-0 text-white text-xl 
                       hover:text-gray-300 transition-colors duration-300
                       active:scale-95 transform"
              >
                ✕
              </button>
              <div className="relative">
                <img
                  src={selectedImage.image}
                  alt={selectedImage.title}
                  className="w-full rounded-lg shadow-xl"
                />
                <div
                  className="absolute top-4 right-4 bg-orange-500 text-white 
                           rounded-full px-3 py-1 text-sm"
                >
                  {formatDate(selectedImage.date)}
                </div>
              </div>
              <div className="mt-4 text-white text-center">
                <p className=" ">{selectedImage.description}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PhotoGallery;
