import React from 'react';
import { FaPhone, FaEnvelope, FaTwitter, FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import DarkModeToggle from './DarkModeToggle';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import { Link } from 'lucide-react';


const TopBar = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full mx-auto xl:max-w-[1920px] 2xl:max-w-[2560px] 3xl:max-w-[3840px] 4xl:max-w-[5120px] 5xl:max-w-[6400px] 6xl:max-w-[7680px]">
    <div className="bg-yellow-400 dark:bg-gray-800 py-2 hidden sm:block">
      <div className="container mx-auto px-4 sm:px-6 md:px-8 flex justify-between items-center">
        {/* Left Side */}
        <div className="flex items-center text-xs sm:text-sm md:text-base text-black dark:text-white space-x-4">
          <div className="flex items-center">
            <FaPhone className="mr-2 rotate-90" />: +255 242 231 489 |
          </div>
          <div className="flex items-center">
            <FaEnvelope className="mr-2" />: {t('S.L.P')} 1001, 71125 Mjini Magharibi, Zanzibar. |
          </div>
          <a href='/faqs' className='hover:bg-gray-300 dark:hover:bg-yellow-400'>FAQs</a>
        </div>

        {/* Right Side */}
        <div className="flex items-center space-x-6 text-black dark:text-white">
        <LanguageSwitcher />
          <DarkModeToggle />
          <div className="flex items-center space-x-4">
            {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="hover:text-gray-700 dark:hover:text-gray-300" />
            </a> */}
            <a href="https://facebook.com/ZECpages" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="hover:text-gray-700 dark:hover:text-gray-300" />
            </a>
            <a href="https://instagram.com/zec_zanzibar" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="hover:text-gray-700 dark:hover:text-gray-300" />
            </a>
            <a href="https://youtube.com/@ZECONLINETV" target="_blank" rel="noopener noreferrer">
              <FaYoutube className="hover:text-gray-700 dark:hover:text-gray-300" />
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TopBar;