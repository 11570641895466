import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Correct imports
import { HelmetProvider } from 'react-helmet-async';
import './i18n';
import TopBar from './components/Topbar';
import NavBar from './components/Navbar';
import Appbar from './components/Appbar';
import Footer from './components/footer';
import FloatingBadge from './components/FloatingBadge'; 
import PageTransition from './components/PageTransition';
import Home from './pages/home';
import Historia from './pages/Historia';
import Contact from './pages/Contact';
import Wajumbe from './pages/Wajumbe';
import Utawala from './pages/Utawala';
import DiraNaDhamira from './pages/DiraNaDhamira';
import MaadiliYaMsingi from './pages/MaadiliYaMsingi';
import MuundoWaTume from './pages/MuundoWaTume';
import NewsGrid from './pages/habari/HabariNaMatukio';
import TaarifakwaUmma from './pages/habari/TaarifaKwaUmma';
import Hotuba from './pages/habari/Hotuba';
import MaktabaYaVideo from './pages/habari/MaktabaYaVideo';
import SheriazaUchaguzi from './pages/machapisho/SheriaZaUchaguzi';
import MaadiliyaUchaguzi from './pages/machapisho/MaadiliYaUchaguzi';
import KanunizaUchaguzi from './pages/machapisho/KanuniZaUchaguzi';
import JaridalaTume from './pages/machapisho/JaridaLaTume';
import MatokeoyaUchaguzi from './pages/uchaguzi/MatokeoYaUchaguzi';
import TaarifazaUchaguzi from './pages/uchaguzi/TaarifaZaUchaguzi';
import MzungukowaUchaguzi from './pages/uchaguzi/MzungukoWaUchaguzi';
import MfumowaUchaguzi from './pages/uchaguzi/MfumoWaUchaguzi';
import PhotoGallery from './pages/habari/MaktabaYaPicha';
import FomuMaalum from './pages/machapisho/FomuMaalum';
import ElimuKura from './pages/uchaguzi/ElimuKura';
import NewsPage from './pages/habari/habari-zaidi';
import FAQPage from './pages/faqs';


function App() {

  React.useEffect(() => {
    if (localStorage.getItem('darkMode') === 'true') {
      document.documentElement.classList.add('dark');
    }
  }, []);

  return (
    <HelmetProvider>
    <Router>
      <div className="flex flex-col min-h-screen bg-white dark:bg-gray-900 transition-colors duration-300">
        <TopBar />
        <Appbar />
        <NavBar />
        <main className="flex-grow bg-gray-100 dark:bg-gray-800 transition-colors duration-300">
          <Routes> {/* Correct usage */}
            <Route path="/" element={<Home />} />
            <Route path="/about/history" element={<Historia />} />
            <Route path="/about/wajumbe" element={<Wajumbe />} />
            <Route path="/about/wajumbe" element={<Wajumbe />} />
            <Route path="/about/dira-dhima" element={<DiraNaDhamira />} />
            <Route path="/about/maadili-ya-msingi" element={<MaadiliYaMsingi />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/habari/matukio" element={<NewsGrid />} />
            <Route path="/machapisho/sheria" element={<SheriazaUchaguzi />} />
            <Route path="/machapisho/maadili" element={<MaadiliyaUchaguzi />} />
            <Route path="/machapisho/kanuni" element={<KanunizaUchaguzi />} />
            <Route path="/machapisho/jarida" element={<JaridalaTume />} />
            <Route path="uchaguzi/matokeo-uchaguzi" element={<MatokeoyaUchaguzi />} />
            <Route path="/uchaguzi/taarifa-uchaguzi" element={<TaarifazaUchaguzi />} />
            <Route path="/uchaguzi/mfumo-uchaguzi" element={<MfumowaUchaguzi />} />
            <Route path="/uchaguzi/mzunguko-uchaguzi" element={<MzungukowaUchaguzi />} />
            <Route path="/habari/matangazo" element={<TaarifakwaUmma />} />
            <Route path="/habari/hotuba" element={<Hotuba />} />
            <Route path="/habari/maktaba-video" element={<MaktabaYaVideo />} />
            <Route path="/habari/maktaba-picha" element={<PhotoGallery />} />
            <Route path="/about/utawala" element={<Utawala />} />
            <Route path="/about/muundo-wa-tume" element={<MuundoWaTume />} />
            <Route path="/machapisho/fomu-maalumu" element = {<FomuMaalum />} />
            <Route path="/uchaguzi/elimu-kura" element ={<ElimuKura />} />
            <Route path='/habari-zaidi' element={<NewsPage />} />
            <Route path="/faqs" element={<FAQPage />} />
          </Routes>
          <PageTransition />
        </main>
        <Footer />
        <FloatingBadge /> {/* Add the FloatingBadge component here */}
      </div>
    </Router>
    </HelmetProvider>
  );
}

export default App;